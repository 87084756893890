import React, {  } from 'react';
import { If, Else } from 'react-if';
import { getNumberString, numberWithTwoPlaces } from '../../services/common/Numbers';
import css from './Cart.module.css';

function FinalizeCartPosition({ position, onMessageChange }) {
        let positionPrice = numberWithTwoPlaces(position.product.grosPrice * position.quantity);
        let positionPriceText = getNumberString(positionPrice);

        let newPositionPrice = numberWithTwoPlaces((position.discount) ? positionPrice - position.discount : 0);
        let newPositionPriceText = getNumberString(newPositionPrice);

        return (
            <div className={css.ProductLine}>
                <div className={css.ProductInfo}>
                    <div className="d-flex flex-row" style={{gap: '30px'}}>
                        <div className={css.Image}>
                            <img src={`${process.env.REACT_APP_SHOP_IMAGES_SERVER}/${position.product.photoPath}`} alt={position.product.name} />
                        </div>
                        <div className={css.NameWithQuantity}>
                            <span className={css.Name}>
                                {position.product.name}
                                {position.quantity > 1 && <small>x{position.quantity}</small>}
                            </span>
                            <If condition={position.discount}>
                                <div className={css.ProductDiscount}>
                                    <span className={`${css.ProductPrice} ${css.OldPrice}`}>{positionPriceText} zł</span>
                                    <span className={`${css.ProductPrice} ${css.NewPrice}`}>{newPositionPriceText} zł</span>
                                </div>
                                <Else>
                                    <div className={css.ProductPrice}>{positionPriceText} zł</div>
                                </Else>
                            </If>
                            {/* <span className={css.Quantity}>{position.quantity} {position.product.unit}</span> */}
                        </div>
                    </div>
                </div>
                <span className={css.Label}>Wiadomość do sprzedającego</span>
                <div className="form-floating">
                    <input type="text" className={`form-control ${css.MessageInput}`} placeholder="Treść wiadomości" value={position.message} onChange={(event) => onMessageChange(position, event.target.value)} />
                    <label for={`message-${position.product.id}`} className={css.MessageInputLabel}>Treść wiadomości</label>
                </div>
            </div>
        );
    }

    export default FinalizeCartPosition;