import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../../hooks/CartContext';
import { Badge } from '@mui/material';
import { styled } from '@mui/system';
import CartIcon from '../../images/icons/cart.svg';

const StyledBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
      border: '2px solid #f5f5f5',
      padding: '15px 11px',
      backgroundColor: 'var(--accent-bg-color)',
      color: 'var(--white-text-color)',
      borderRadius: '50%',
      fontSize: '1rem',
    },
  }));

function CartButton() {
    const { getCartQuantity } = useContext(CartContext);

    return (
        <Link to='/cart'>
            <StyledBadge badgeContent={getCartQuantity()} invisible={!getCartQuantity()} color="secondary" max={99}
                         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <img src={CartIcon} alt=""/>
            </StyledBadge>
        </Link>
    );
}

export default CartButton;