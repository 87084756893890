import React from 'react';
import PropTypes from 'prop-types';
import css from './Shop.module.css';
import { IconButton } from '@mui/material';
import { Phone, WatchLater, VolumeUp } from '@mui/icons-material/index';
import { useContext } from 'react';
import { PollyContext } from '../common/Polly';
import { Link } from 'react-router-dom';

function Details(props) {
    const {
        data
    } = props;
    const { readText } = useContext(PollyContext);

    return (
        <div className={css.DetailsContainer}>
            <div className={css.Info}>
                <IconButton
                    className={css.SpeakerButton}
                    onClick={() => readText(data.name)}
                >
                    <VolumeUp />
                </IconButton>
                <div className={css.ShopImage}>
                    <img src={`${process.env.REACT_APP_ADMIN_IMAGES_SERVER}/${data.logoPath}`} alt={data.name} />
                </div>
                <div className={css.InfoContainer}>
                    <div className={css.ShopTitle}>
                        <h6>{data.name}</h6>
                        <span>Poziom 0</span>
                    </div>
                    <div className={css.ShopContactInfo}>
                        <span className={css.ShopContactField}>
                            <Phone />
                            {data.phoneNumber}
                        </span>
                        <span className={css.ShopContactField}>
                            <WatchLater />
                            Dzisiaj otwarte 10:00 - 21:00
                        </span>
                    </div>
                    <Link to={`shop/${data.id}`} className="bp-text-button">POKAŻ WIĘCEJ</Link>
                </div>
            </div>
            <div className={css.DescriptionContainer}>
                <IconButton
                    className={css.SpeakerButton}
                    onClick={() => readText(data.description)}
                >
                    <VolumeUp />
                </IconButton>
                <h6>Opis</h6>
                <div className={css.Description}>
                    {data.description}
                </div>
            </div>
        </div>
    )
}

Details.propTypes = {
    data: PropTypes.object.isRequired
};

Details.defaultProps = {
    data: null
};

export default Details;

