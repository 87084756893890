import React, { useContext } from 'react';
import { VolumeUp } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import css from './Home.module.css';
import { PollyContext } from '../common/Polly';

const CategoryBox = ({ index, category, onSelect }) => {
    const { readText } = useContext(PollyContext);

    const handleRead = async (event) => {
        event.stopPropagation();

        await readText(category.name);
    }

    return <div key={index} className={css.CategoryBox} onClick={onSelect}>
      <div className="d-flex justify-content-end w-100">
      <IconButton
            className={css.SpeakerButton}
            onClick={handleRead}
        >
            <VolumeUp />
        </IconButton>
      </div>
      <h6 className={css.CategoryName}>{category.name}</h6>
      <div className={css.CategoryImage}>
        <img src={`${process.env.REACT_APP_ADMIN_IMAGES_SERVER}/${category.iconPath}`} alt={category.name} />
      </div>
    </div>;
  }

  export default CategoryBox;