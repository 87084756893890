import React, { Component, createRef } from 'react';
import Header from './Header/Header';
import Search from './Search/Search';
import { GlobalStyles } from '@mui/material';

const defaultColors = {
  '--main-text-color': '#101010',
  '--gray-text-color': '#7C7C7C',
  '--light-gray-color': '#B5B5B5',
  '--gray-color-1': '#D1D1D1',
  '--gray-color-2': '#A8A8A8',
  '--white-text-color': '#FFFFFF',
  '--accent-text-color': '#09CAB3',
  '--accent-bg-color': '#09CAB3',
  '--main-bg-color': '#F0F2F4',
  '--white-bg-color': '#FFFFFF',
  '--main-button-bg': '#E3E3E3',
  '--main-button-text': '#7C7C7C',
  '--accent-button-bg': '#09CAB3',
  '--accent-button-text': '#FFFFFF',
  '--accent-button-shadow-color': '#09CAB34D',
  '--logo-filter': 'none',
  '--background-image-filter': 'none',
  '--gray-border-color': '#707070',
  '--header-text-color': '#202020',
  '--active-box-border-color': '#09CAB3',
  '--active-box-shadow-color': '#09cab379',
  '--active-variant-shadow': 'none',
  '--ribbon-bg-color': '#F5F5F5',
  '--ribbon-text-color': '#B5B5B5',
  '--scroll-bar-track': '#d3d2d2',
  '--scroll-bar-thumb': '#aaaaaa',
  '--shadow-color': '#00000029',
  '--shadow-hover-color': '#0000005b',
  '--product-box-border': 'none',
};

export class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      rootTextSize: '16px',
      colors: {...defaultColors},
    };

    this.timer = null;
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleTextSizeChange = this.handleTextSizeChange.bind(this);
    this.handleColorsChange = this.handleColorsChange.bind(this);
  }

  componentDidMount() {
    document.body.style.fontSize = this.rootTextSize;
  }

  handleSearchChange(text) {
    if (!text) {
      this.setState({
        search: ''
      });

      return;
    }

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({
        search: text
      });
    }, 500);
  }

  handleTextSizeChange(size) {
    this.setState({
      rootTextSize: size
    });
  }

  handleColorsChange(styles) {
    this.setState({
      colors: !!styles ? {...styles} : {...defaultColors}
    });
  }

  render () {
    return (
      <div
        className="h-100 overflow-hidden d-flex flex-column"
        style={{
          fontSize: this.state.rootTextSize,
        }}
      >
        <GlobalStyles styles={{ ':root': {...this.state.colors}}} />
        <Header
          phrase={this.state.search}
          textSize={this.state.rootTextSize}
          onSearchChange={this.handleSearchChange}
          onTextSizeChange={this.handleTextSizeChange}
          onColorsChange={this.handleColorsChange}
        />
        {
          this.state.search
          ? <Search phrase={this.state.search} onClick={() => this.setState({search: ''})}/>
          : this.props.children
        }
      </div>
    );
  }
}
