import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import css from './Product.module.css';
import { IconButton } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { getTwoPlacesNumberString } from '../../services/common/Numbers';
import ShopPictogram from '../../images/icons/shop_image.svg';
import { useContext } from 'react';
import { PollyContext } from '../common/Polly';

function Info(props) {
    const {
        product,
        quantity,
        shop,
        onIncrase,
        onDecrase,
        onQuantityChange
    } = props;
    const { readText } = useContext(PollyContext);

    const readProduct = async () => {
        await readText(product.name + '. ' + product.description);
    }

    return (
        <>
            <IconButton
                className={css.SpeakerButton}
                onClick={readProduct}
            >
                <VolumeUpIcon />
            </IconButton>
            <div className={css.ProductInfo}>
                <span className={css.Name}>
                    {product.name}
                    <div className={css.ShopNameWithPictogram}>
                        <img src={ShopPictogram} alt=""/>
                        {shop.name}
                    </div>
                </span>
                <div className={css.Price}>
                    {getTwoPlacesNumberString(product.grosPrice)} zł / {product.unit}
                    <div className={css.Quantity}>
                        <IconButton className={css.Decrase} onClick={onDecrase}><IndeterminateCheckBoxIcon /></IconButton>
                        <input type="text" value={quantity} onChange={onQuantityChange}/>
                        <IconButton className={css.Incrase} onClick={onIncrase}><AddBoxIcon /></IconButton>
                    </div>
                </div>
            </div>
        </>
    );
}

Info.propTypes = {
    product: PropTypes.object.isRequired,
    quantity: PropTypes.number.isRequired,
    shop: PropTypes.object.isRequired,
    onIncrase: PropTypes.func.isRequired,
    onDecrase: PropTypes.func.isRequired,
    onQuantityChange: PropTypes.func.isRequired
};

export default Info;