import React from 'react';
import { Provider } from 'react-alert'
import AlertMUITemplate from 'react-alert-template-mui';
import { alertOptions } from './configurations/alertOptions';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home/Home';
import { Shop } from './components/Shop/Shop';
import { Product } from './components/Product/Product';
import Thanks from './components/Thanks/Thanks';
import Login from './components/Login/LoginComponent';

import './fonts/fonts.css';
import './custom.css'
import Cart from './components/Cart/Cart';
import { CartContextProvider } from './hooks/CartContext';
import LogoutComponent from './components/Login/LogoutComponent';
import { PollyContextProvider } from './components/common/Polly';
import { LayoutContextProvider } from './hooks/LayoutContext';
import FinalizeBlind from './components/Blind/FinalizeBlind';
import ThanksBlind from './components/Blind/ThanksBlind';

const isAuthenticated = localStorage.getItem('token') !== null && localStorage.getItem('token') !== ''
    && localStorage.getItem('token') !== 'undefined';

const App = () => (isAuthenticated ? generateMainPage() : generateLoginPage());

const generateMainPage = () => {
    return (
        <PollyContextProvider>
            <LayoutContextProvider>
                <CartContextProvider>
                    <Provider template={AlertMUITemplate} {...alertOptions}>
                        <Layout>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/shop/:id' component={Shop} />
                            <Route exact path='/product/:id' component={Product} />
                            <Route exact path='/cart' component={Cart} />
                            <Route exact path='/thanks/:id' component={Thanks} />
                            <Route exact path='/logout' component={LogoutComponent} />
                            <Route exact path='/finalize-blind' component={FinalizeBlind} />
                            <Route exact path='/thanks-blind/:id' component={ThanksBlind} />
                        </Layout>
                    </Provider>
                </CartContextProvider>
            </LayoutContextProvider>
        </PollyContextProvider>
    );
}

const generateLoginPage = () => {
    return (
        <Provider template={AlertMUITemplate} {...alertOptions}>
            <Login />
        </Provider>
    );
}

export default App;
