import { useContext, useState } from "react"
import { CartContext } from "../../hooks/CartContext"

import css from './Blind.module.css';
import { useEffect } from "react";
import { PollyContext } from "../common/Polly";
import useRightClickHold from "../../hooks/RightClickHold";
import { apiOrder } from '../../services/api/order/Order';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getTwoPlacesNumberString } from "../../services/common/Numbers";

const FinalizeBlind = () => {
    const { cart, getCartAmount } = useContext(CartContext);
    const { readText } = useContext(PollyContext);
    const { push } = useHistory();
    const [finalized, setFinalized] = useState(false);

    const finalizeOrder = async () => {
        if (finalized) return;

        let data = {
            items: cart.map((item) => ({
                productId: item.product.id,
                shopId: item.shop.id,
                price: item.product.grosPrice,
                quantity: item.quantity,
                message: item.message
            })),
            deliveryMethod: 2,
            paymentMethod: 1,
        };

        var res = await apiOrder.post(data);

        if (res.id) {
            setFinalized(true);
            push(`/thanks-blind/${res.id}`);
        } else {
            console.error(res);
        }
    }

    useEffect(() => {
        readText(`
            <speak>
                <break time="1s" />
                <p>Proszę potwierdź swoje zamówienie</p>
                <p>Oto lista zamówionych produktów:</p>
                ${cart.map((pos) => `<p>${pos.product.name}</p>`).toString()}
                <p>Dokonaj płatności kartą na kwotę <say-as interpret-as="number">${getTwoPlacesNumberString(getCartAmount())}</say-as> zł</p>
                <break time="1s"/>
                <p>Aby potwierdzić zamówienie, przytrzymaj przycisk</p>
            </speak>`
        , true);
    }, [readText, cart])

    useRightClickHold(finalizeOrder, 2000);

    return (
        <div className={css.MainContainer}>
            <div className={css.Container}>
                <div className={css.Message}>
                    <h1>Proszę potwierdź swoje zamówienie</h1>
                    <h3>Oto lista zamówionych produktów:</h3>
                    {cart.map((pos, index) => (
                        <p key={index}>{index + 1}. {pos.product.name}</p>
                    ))}
                    <h3 style={{marginTop: 50}}>Dokonaj płatności kartą na kwotę {getTwoPlacesNumberString(getCartAmount())} zł</h3>
                    <h3>Aby potwierdzić zamówienie, przytrzymaj przycisk</h3>
                </div>
            </div>
        </div>
    )
}

export default FinalizeBlind;