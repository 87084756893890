import React, { Component } from 'react';
import Breadcrump from '../common/Breadcrump';
import { apiProduct } from '../../services/api/shop/Product';
import css from './Product.module.css';
import { Grid, IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Image from './Image';
import Info from './Info';
import Variants from './Variants';
import Description from './Description';
import AddToCart from './AddToCart';
import { LayoutContext } from '../../hooks/LayoutContext';

export class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: null,
            variants: [],
            shop: null,
            breadcrumb: this.makeBreadcrumb(''),
            quantity: 1
        };

        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.incrase = this.incrase.bind(this);
        this.decrase = this.decrase.bind(this);
    }

    componentDidMount() {
        this.get(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id)
            this.get(this.props.match.params.id);
    }

    async get(id) {
        let data = await apiProduct.getSingle(id);

        if (data && data.product)
            this.setState({
                product: data.product,
                variants: this.makeVariants(data.product, data.variants),
                shop: data.shop,
                breadcrumb: this.makeBreadcrumb(data.shop, data.product.name)
            });
    }

    makeBreadcrumb(shop, productName) {
        if (!shop)
            return [];

        return [
            {
                url: '/?allShops',
                text: 'Wszystkie sklepy'
            },
            {
                url: `/shop/${shop.id}`,
                text: shop.name
            },
            {
                url: null,
                text: productName
            }
        ];
    }

    makeVariants(product, variants) {
        variants.push({
            variantId: product.id,
            variantName: product.variantName
        });

        return variants.sort((a, b) => {
            if (a.variantName > b.variantName)
                return 1;

            if (b.variantName > a.variantName)
                return -1;

            return 0;
        })
    }

    handleQuantityChange(event) {
        this.setState({
            quantity: Number(event.target.value)
        });
    }

    incrase() {
        this.setState({
            quantity: this.state.quantity + 1
        });
    }

    decrase() {
        if (this.state.quantity > 1)
            this.setState({
                quantity: this.state.quantity - 1
            });
    }

    render() {
        return (
            <LayoutContext.Consumer>
                {({ headerHeight }) => (
                    <div className={css.MainContainer} style={{height: `calc(100vh - ${headerHeight}px)`}}>
                        <Breadcrump items={this.state.breadcrumb} historyGoBack />
                        {this.state.product ?
                            <div className={css.ProductContainer}>
                                <div className={css.ProductDetailsContainer}>
                                    <div style={{ overflowY: 'auto', flex: 1, padding: '30px 0 60px 0', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <Grid item className={css.ProductImageContainer}>
                                            <Image productName={this.state.product.name} photo={this.state.product.photoPath} />
                                        </Grid>
                                        <Grid item className={css.ProductInfoContainer}>
                                            <div className={css.Container}>
                                                <Info
                                                    product={this.state.product}
                                                    quantity={this.state.quantity}
                                                    shop={this.state.shop}
                                                    onIncrase={this.incrase}
                                                    onDecrase={this.decrase}
                                                    onQuantityChange={this.handleQuantityChange}
                                                />
                                                <Variants items={this.state.variants} current={this.state.product.id} />
                                                <Description text={this.state.product.description} />
                                            </div>
                                        </Grid>
                                    </div>
                                </div>
                                <AddToCart shop={this.state.shop} product={this.state.product} quantity={this.state.quantity} />
                            </div>
                            : <p>Ładowanie danych produktu...</p>
                        }
                    </div>
                )}
            </LayoutContext.Consumer>
        )
    }
}