import React, { useState, useEffect } from 'react';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '../../images/logo_small.png';
import PolandFlag from '../../images/flags/pl-flag.png';
import SpainFlag from '../../images/flags/es-flag.png';
import ChinaFlag from '../../images/flags/cn-flag.png';
import UKFlag from '../../images/flags/uk-flag.png';
import CartButton from './CartButton';
import SearchButton from './SearchButton';
import SpeechText from '../common/SpeechText';
import './Header.css';
import { useRef } from 'react';
import { useContext } from 'react';
import { LayoutContext } from '../../hooks/LayoutContext';

const colorSet1 = {
    '--main-text-color': '#000000',
    '--gray-text-color': '#000000',
    '--light-gray-color': '#000000',
    '--gray-color-1': '#000000',
    '--gray-color-2': '#000000',
    '--white-text-color': '#FFFFFF',
    '--accent-text-color': '#000000',
    '--accent-bg-color': '#000000',
    '--main-bg-color': '#FFFFFF',
    '--main-button-bg': '#E3E3E3',
    '--main-button-text': '#7C7C7C',
    '--accent-button-bg': '#000000',
    '--accent-button-text': '#FFFFFF',
    '--accent-button-shadow-color': '#0000004D',
    '--logo-filter': 'grayscale(1)',
    '--background-image-filter': 'grayscale(1)',
    '--white-bg-color': '#FFFFFF',
    '--gray-border-color': '#000000',
    '--header-text-color': '#000000',
    '--active-box-border-color': '#000000',
    '--active-box-shadow-color': '#000000',
    '--active-variant-shadow': '0px 0px 12px #000000',
    '--ribbon-bg-color': '#000000',
    '--ribbon-text-color': '#FFFFFF',
    '--scroll-bar-track': '#FFFFFF',
    '--scroll-bar-thumb': '#000000',
    '--shadow-color': '#00000029',
    '--shadow-hover-color': '#0000005b',
    '--product-box-border': '2px solid #000000',
};

const colorSet2 = {
    '--main-text-color': '#FFFFFF',
    '--gray-text-color': '#FFFFFF',
    '--light-gray-color': '#FFFFFF',
    '--gray-color-1': '#FFFFFF',
    '--gray-color-2': '#FFFFFF',
    '--white-text-color': '#000000',
    '--accent-text-color': '#FFFFFF',
    '--accent-bg-color': '#FFFFFF',
    '--main-bg-color': '#000000',
    '--main-button-bg': '#E3E3E3',
    '--main-button-text': '#7C7C7C',
    '--accent-button-bg': '#FFFFFF',
    '--accent-button-text': '#000000',
    '--accent-button-shadow-color': '#FFFFFF4D',
    '--logo-filter': 'grayscale(1) invert(1)',
    '--background-image-filter': 'grayscale(1)',
    '--white-bg-color': '#000000',
    '--gray-border-color': '#FFFFFF',
    '--header-text-color': '#FFFFFF',
    '--active-box-border-color': '#FFFFFF',
    '--active-box-shadow-color': '#FFFFFF',
    '--active-variant-shadow': '0px 0px 12px #FFFFFF',
    '--ribbon-bg-color': '#FFFFFF',
    '--ribbon-text-color': '#000000',
    '--scroll-bar-track': '#000000',
    '--scroll-bar-thumb': '#FFFFFF',
    '--shadow-color': '#FFFFFF29',
    '--shadow-hover-color': '#FFFFFF5b',
    '--product-box-border': '2px solid #FFFFFF',
};
const colorSet3 = {
    '--main-text-color': '#000000',
    '--gray-text-color': '#000000',
    '--light-gray-color': '#000000',
    '--gray-color-1': '#000000',
    '--gray-color-2': '#000000',
    '--white-text-color': '#FFFF00',
    '--accent-text-color': '#000000',
    '--accent-bg-color': '#000000',
    '--main-bg-color': '#FFFF00',
    '--main-button-bg': '#E3E3E3',
    '--main-button-text': '#7C7C7C',
    '--accent-button-bg': '#000000',
    '--accent-button-text': '#FFFF00',
    '--accent-button-shadow-color': '#0000004D',
    '--logo-filter': 'grayscale(1)',
    '--background-image-filter': 'grayscale(1)',
    '--white-bg-color': '#FFFF00',
    '--gray-border-color': '#000000',
    '--header-text-color': '#000000',
    '--active-box-border-color': '#000000',
    '--active-box-shadow-color': '#000000',
    '--active-variant-shadow': '0px 0px 12px #000000',
    '--ribbon-bg-color': '#000000',
    '--ribbon-text-color': '#FFFF00',
    '--scroll-bar-track': '#FFFF00',
    '--scroll-bar-thumb': '#000000',
    '--shadow-color': '#00000029',
    '--shadow-hover-color': '#0000005b',
    '--product-box-border': '2px solid #000000',
};
const colorSet4 = {
    '--main-text-color': '#FFFF00',
    '--gray-text-color': '#FFFF00',
    '--light-gray-color': '#FFFF00',
    '--gray-color-1': '#FFFF00',
    '--gray-color-2': '#FFFF00',
    '--white-text-color': '#000000',
    '--accent-text-color': '#FFFF00',
    '--accent-bg-color': '#FFFF00',
    '--main-bg-color': '#000000',
    '--main-button-bg': '#E3E3E3',
    '--main-button-text': '#7C7C7C',
    '--accent-button-bg': '#FFFF00',
    '--accent-button-text': '#000000',
    '--accent-button-shadow-color': '#FFFF004D',
    '--logo-filter': 'grayscale(1) invert(1)',
    '--background-image-filter': 'grayscale(1)',
    '--white-bg-color': '#000000',
    '--gray-border-color': '#707070',
    '--header-text-color': '#FFFF00',
    '--active-box-border-color': '#FFFF00',
    '--active-box-shadow-color': '#FFFF00',
    '--active-variant-shadow': '0px 0px 12px #FFFF00',
    '--ribbon-bg-color': '#FFFF00',
    '--ribbon-text-color': '#000000',
    '--scroll-bar-track': '#000000',
    '--scroll-bar-thumb': '#FFFF00',
    '--shadow-color': '#FFFF0029',
    '--shadow-hover-color': '#FFFF005b',
    '--product-box-border': '2px solid #FFFF00',
};

function Header(props) {
    const [search, setSearch] = useState('');
    const [isLogoPressed, setIsLogoPressed] = useState(true);
    const [selectedColors, setSelectedColors] = useState(null);

    const onSearchChange = props.onSearchChange;

    const clearSearch = (event) => {
        if (!isLogoPressed) {
            event.preventDefault();
        }

        setSearch('');
        onSearchChange();
    }

    let timer = null;

    const handleOnMouseDown = () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setIsLogoPressed(false);
            window.location.href = '/logout';
        }, 1000);
    }

    const handleOnMouseUp = () => {
        clearTimeout(timer);
    }

    const handleSearchChange = (text) => {
        setSearch(text);
        onSearchChange(text);
    }

    const handleClearSearch = () => {
        handleSearchChange('');
    }

    const handleColorsChange = (set, styles) => {
        if (set === selectedColors) {
            setSelectedColors(null);
            props.onColorsChange(null);
            return;
        }

        setSelectedColors(set);
        props.onColorsChange(styles);
    }

    useEffect(() => {
        if (search !== props.phrase)
            setSearch(props.phrase);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.phrase]);

    return (
        <header>
            <Grid container className="header-main">
                <Grid item className="logo-container">
                    <Link
                        to={{pathname: '/', key: uniqueId()}}
                        onClick={clearSearch}
                        onMouseDown={handleOnMouseDown}
                        onMouseUp={handleOnMouseUp}
                    >
                        <img src={Logo} alt="logo" className="w-100"/>
                    </Link>
                </Grid>
                <Grid item className="circle-buttons-container">
                    <div className="locale-buttons">
                        <img className="circle-button" src={PolandFlag} alt="Polish" />
                        <img className="circle-button" src={SpainFlag} alt="Spanish" />
                        <img className="circle-button" src={UKFlag} alt="English" />
                        <img className="circle-button" src={ChinaFlag} alt="Chinese" />
                    </div>
                    <div className="accessibility-buttons">
                    <div className="d-flex">
                        <div
                            className={`circle-button with-text ${props.textSize === '16px' ? 'active' : ''}`}
                            onClick={() => props.onTextSizeChange('16px')}
                        >
                            <span className="small">A</span>
                        </div>
                        <div
                            className={`circle-button with-text ${props.textSize === '20px' ? 'active' : ''}`}
                            onClick={() => props.onTextSizeChange('20px')}
                        >
                            <span className="medium">A</span>
                        </div>
                        <div
                            className={`circle-button with-text ${props.textSize === '24px' ? 'active' : ''}`}
                            onClick={() => props.onTextSizeChange('24px')}
                        >
                            <span className="big">A</span>
                        </div>
                    </div>
                    <div className="d-flex black-border">
                        <div
                            className={`circle-button with-text colors-1 ${selectedColors === 1 ? 'active' : ''}`}
                            onClick={() => handleColorsChange(1, colorSet1)}
                        >
                            <span className="medium">A</span>
                        </div>
                        <div
                            className={`circle-button with-text colors-2 ${selectedColors === 2 ? 'active' : ''}`}
                            onClick={() => handleColorsChange(2, colorSet2)}
                        >
                            <span className="medium">A</span>
                        </div>
                        <div
                            className={`circle-button with-text colors-3 ${selectedColors === 3 ? 'active' : ''}`}
                            onClick={() => handleColorsChange(3, colorSet3)}
                        >
                            <span className="medium">A</span>
                        </div>
                        <div
                            className={`circle-button with-text colors-4 ${selectedColors === 4 ? 'active' : ''}`}
                            onClick={() => handleColorsChange(4, colorSet4)}
                        >
                            <span className="medium">A</span>
                        </div>
                    </div>
                    </div>
                </Grid>
                <Grid item className="cart-button-container">
                    <CartButton />
                </Grid>
                <Grid item xs={12} className="search-box-container">
                    <TextField
                        className="main-search-box"
                        fullWidth
                        placeholder="Szukaj produktu lub sklepu"
                        InputProps={{
                            startAdornment: <SearchButton active={!!search} onClick={handleClearSearch} />,
                            endAdornment: <SpeechText onSpeech={(text) => {
                                handleSearchChange(text);
                            }} />
                        }}
                        variant="outlined"
                        value={search}
                        onChange={(event) => handleSearchChange(event.target.value)}
                    />
                </Grid>
            </Grid>
        </header>
    )
}

// class Header extends Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             search: '',
//         }

//         this.onSearchChange = props.onSearchChange;
//         this.setSearch = this.setSearch.bind(this);
//         this.clearSearch = this.clearSearch.bind(this);
//         this.handleOnMouseDown = this.handleOnMouseDown.bind(this);
//         this.handleOnMouseUp = this.handleOnMouseUp.bind(this);

//         this.timer = null;
//     }

//     setSearch(event) {
//         this.setState({
//             search: event.target.value,
//         }, () => this.onSearchChange(event));
//     }

//     clearSearch() {
//         this.setState({
//             search: '',
//         }, () => this.onSearchChange());
//     }

//     handleOnMouseDown() {
//         clearTimeout(this.timer);
//         this.timer = setTimeout(() => {
//             this.props.history.push('/logout');
//         }, 1000);
//     }

//     handleOnMouseUp() {
//         clearTimeout(this.timer);
//     }

//     render() {
//         return (
//             <header>
//                 <Grid container className="header-main">
//                     <Grid item md={2} xs={12} className="logo-container">
//                         <Link
//                             to={{pathname: '/', key: uniqueId()}}
//                             onClick={this.clearSearch}
//                             onMouseDown={this.handleOnMouseDown}
//                             onMouseUp={this.handleOnMouseUp}
//                         >
//                             <img src={Logo} alt="logo" className="w-100"/>
//                         </Link>
//                     </Grid>
//                     <Grid item md={4} xs={12} className="search-box-container">
//                         <TextField
//                             className="main-search-box"
//                             fullWidth
//                             placeholder="Szukaj produktu lub sklepu"
//                             InputProps={{
//                                 startAdornment: <InputAdornment position="start"><SearchIcon className="search-icon" /></InputAdornment>,
//                                 endAdornment: <InputAdornment position="end"><IconButton><MicNoneIcon className="search-icon"/></IconButton></InputAdornment>
//                             }}
//                             variant="outlined"
//                             value={this.state.search}
//                             onChange={this.setSearch}
//                         />
//                     </Grid>
//                     <Grid item md={6} xs={12} className="circle-buttons-container">
//                         <div>
//                             <img className="circle-button" src={PolandFlag} alt="Polish" />
//                             <img className="circle-button" src={SpainFlag} alt="Spanish" />
//                             <img className="circle-button" src={UKFlag} alt="English" />
//                             <img className="circle-button" src={ChinaFlag} alt="Chinese" />
//                         </div>
//                         <div className="d-flex">
//                             <div className="circle-button with-text"><span className="small">A</span></div>
//                             <div className="circle-button with-text"><span className="medium">A</span></div>
//                             <div className="circle-button with-text"><span className="big">A</span></div>
//                         </div>
//                         <div className="d-flex black-border">
//                             <div className="circle-button with-text colors-1"><span className="medium">A</span></div>
//                             <div className="circle-button with-text colors-2"><span className="medium">A</span></div>
//                             <div className="circle-button with-text colors-3"><span className="medium">A</span></div>
//                             <div className="circle-button with-text colors-4"><span className="medium">A</span></div>
//                         </div>
//                         <CartButton />
//                     </Grid>
//                 </Grid>
//             </header>
//         );
//     }
// }

Header.propTypes = {
    onSearchChange: PropTypes.func.isRequired
};

export default Header;