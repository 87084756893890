import deliveryMan from '../images/icons/deliveryMan.png';
import deliveryMachine from '../images/icons/deliveryMachine.png';
import deliveryInPlace from '../images/icons/deliveryInPlace.png';

export const deliveryMethods = [
    {
        id: 0,
        name: 'Kurier',
        icon: deliveryMan,
        price: 10,
        needAddress: true
    },
    {
        id: 1,
        name: 'Paczkomat',
        icon: deliveryMachine,
        price: 10,
        needAddress: true
    },
    {
        id: 2,
        name: 'Na miejscu',
        icon: deliveryInPlace,
        price: 0,
        needAddress: false
    }
];