import { useContext } from "react"
import css from './Blind.module.css';
import { useEffect } from "react";
import { PollyContext } from "../common/Polly";
import { apiOrder } from '../../services/api/order/Order';
import { useState } from "react";
import { CartContext } from "../../hooks/CartContext";
import { LayoutContext } from "../../hooks/LayoutContext";

const ThanksBlind = (props) => {
    const { readText } = useContext(PollyContext);
    const { removeAll } = useContext(CartContext);
    const { setBlindMode } = useContext(LayoutContext);
    const [orderNumber, setOrderNumber] = useState('');

    useEffect(() => {
        apiOrder.getCustom(`/number/${props.match.params.id}`).then(({number}) => {
            setOrderNumber(number);
            readText(`
            <speak>
                <p>Dziękujemy za zakup</p>
                <p>
                    Numer Twojego zamówienia to:
                    <break time="1s"/>
                    <prosody rate="slow">
                        <say-as interpret-as="spell-out">
                            ${number.replace(/-/g, '')}
                        </say-as>
                    </prosody>
                </p>
                <p>Kolejne zamówienia zawsze po przytrzymaniu przycisku przez 3 sekundy</p>
            </speak>`
        , true);
        }).finally(() => {
            removeAll();
            setBlindMode(false);
        });



    }, [readText, props]);

    return (
        <div className={css.MainContainer}>
            <div className={css.Container}>
                <div className={css.Message}>
                    <h1>Dziękujemy za zakup</h1>
                    <h3>Numer Twojego zamówienia to: {orderNumber}</h3>
                </div>
            </div>
        </div>
    )
}

export default ThanksBlind;