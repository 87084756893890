import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { apiLogin } from '../../services/api/accounts/Login';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';

import BigLogo from '../../images/logo_big.svg';
import styles from './Login.module.css';
import FormField from '../common/FormField';

const LoginComponent = () => {
    const { register, handleSubmit, setError, formState } = useForm();
    const [validationMessage, setValidationMessage] = useState('');

    const onSubmit = async (data) => {
        let result = await apiLogin.post(data);

        console.log(result);

        if (result.error) {
            console.error(result.error);
        } else {
            localStorage.setItem('token', result.token);
            localStorage.setItem('name', result.name);

            window.location.href = "/";
        }
    };

    return (
        <Container component="main" className={`${styles.LoginContainer} height100`}>
            <img src={BigLogo} alt="" />
            <form onSubmit={handleSubmit(onSubmit)} className={styles.LoginForm}>
                {validationMessage && <div className="validation-error">{validationMessage}</div>}
                <div className="form-floating">
                    <FormField
                        props={{
                            autoFocus: true,
                            required: true,
                            type: "text",
                            id: "serviceCode",
                            placeholder: "Kod serwisowy",
                        }}
                        capitalized
                        className="form-control"
                        register={register}
                        labelClassName={styles.MessageInputLabel}
                    />
                </div>
                <div className="form-floating">
                    <FormField
                        props={{
                            required: true,
                            type: "password",
                            id: "password",
                            placeholder: "Hasło",
                        }}
                        className="form-control"
                        register={register}
                        labelClassName={styles.MessageInputLabel}
                    />
                </div>
                <button type="submit" className={styles.LoginButton}>Zaloguj</button>
            </form>
        </Container>
    );
}

export default LoginComponent;