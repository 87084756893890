import React, { Component } from 'react';
import { apiShopCategory } from '../../services/api/shop/Category';
import css from './Home.module.css';
import CategoryBox from './CategoryBox';

export class Categories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: []
    }

    this.get();
  }

  async get() {
    let data = await apiShopCategory.getCustom('/list');

    if (data && data.categories)
      this.setState({
        categories: data.categories
      });
    else
      this.setState({
        categories: []
      });
  }

  render () {
    return (
      <div className={css.TabContainer}>
        {this.state.categories.length > 0 &&
          <div className={css.CategoryBoxContainer}>
              {this.state.categories.map((category, index) => (
                  <CategoryBox
                    index={index}
                    category={category}
                    onSelect={() => this.props.onCategorySelect(category)}
                  />
                ))
              }
          </div>
        }
      </div>
    );
  }


}
