import React from 'react';
import PropTypes from 'prop-types';
import css from './Product.module.css';

function Image(props) {
    const {
        productName,
        photo
    } = props;

    return (
        <div className={css.ProductImage}>
            <img src={`${process.env.REACT_APP_SHOP_IMAGES_SERVER}/${photo}`} alt={productName} />
        </div>
    );
}

Image.propTypes = {
    productName: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired
};

export default Image;