import React from 'react';
import PropTypes from 'prop-types';
import css from './Product.module.css';
import { Link } from 'react-router-dom';

function Variants(props) {
    const {
        items,
        current
    } = props;

    return (
        <div className={css.ProductVariants}>
            {items.map((variant, index) =>
                <Link key={index} to={`/product/${variant.variantId}`} className={(current === variant.variantId) ? css.Active : ''}>{variant.variantName}</Link>
            )}
        </div>
    );
}

Variants.propTypes = {
    items: PropTypes.array.isRequired,
    current: PropTypes.number.isRequired
};

export default Variants;