import _ from "lodash";
import css from './common.module.css';

function FormField({ props, capitalized, className, register, labelClassName, onChange }) {
    const handleChange = (event) => {
        if (capitalized)
            event.target.value = _.capitalize(event.target.value);

        if (_.isFunction(onChange))
            onChange(event);
    }

    return (
        <>
            <input
                {...props}
                {...register(props.id, { required: props.required })}
                className={`${className} ${css.FormField}`}
                onChange={handleChange} />
            <label for="name" className={labelClassName}>
                {props.placeholder} {props.required ? '*' : ''}
            </label>
        </>
    );
}

export default FormField;