import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Grid } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { getAlertConfig } from '../../configurations/alertOptions';
import { deliveryMethods } from '../../configurations/deliveryMethods';
import { CartContext } from '../../hooks/CartContext';
import Breadcrump from '../common/Breadcrump';
import StepperComponent from './StepperComponent';
import Summary from './Summary';
import SummarySidebar from './SummarySidebar';
import Finalize from './Finalize';
import FinalizeSidebar from './FinalizeSidebar';
import css from './Cart.module.css';
import { LayoutContext } from '../../hooks/LayoutContext';

function Cart() {
    const [activeStep, setActiveStep] = useState(0);
    const [deliveryMethod, setDeliveryMethod] = useState(deliveryMethods[0]);
    const [newsletter, setNewsletter] = useState(false);
    const [formRef, setFormRef] = useState(useRef());
    const [breadcrumb] = useState([{ url: null, text: 'Koszyk' }]);
    const [discount, setDiscount] = useState(0);
    const { getCartAmount, getCartDiscount, clearDiscount, removeAll } = useContext(CartContext);
    const history = useHistory();
    const alert = useAlert();

    const getDiscount = useCallback(
        () => {
            setDiscount(getCartDiscount());
        },
        [getCartDiscount]
    );

    const handleCartDiscount = (discountAmount, isPercentage) => {
        clearDiscount();
        setDiscount((isPercentage) ? getCartAmount() * (discountAmount / 100) : discountAmount);
    }

    const handleNext = () => {
        if (activeStep < 2)
            setActiveStep(activeStep + 1);
    };

    const handleSubmitForm = () => {
        formRef.dispatchEvent(
            new Event("submit", { bubbles: true, cancelable: true })
        )
    };

    const handleBackToCart = async () => {
        await alert.show("Czy na pewno chcesz cofnąć się do koszyka? Dane dostawy nie zostaną zapisane!", getAlertConfig({
            closeText: 'Nie',
            actions: [
                {
                    copy: 'Tak',
                    onClick: async () => {
                        setActiveStep(0);
                    }
                }
            ]
        }));
    };

    const handleDiscardCart = async () => {
        await alert.show("Czy na pewno chcesz anulować zamówienie?", getAlertConfig({
            closeText: 'Nie',
            actions: [
                {
                    copy: 'Tak',
                    onClick: async () => {
                        removeAll();
                        history.push('/');
                    }
                }
            ]
        }));
    };

    useEffect(() => {
        getDiscount();
    }, [getDiscount]);


    if (!getCartAmount())
        return (
            <div className={css.EmptyCartContainer}>
                <h2>Twój koszyk jest pusty</h2>
                <p>Sprawdź dostępne <Link to={{ pathname: '/', query: { showCategories: false } }}>sklepy</Link> i <Link to={{ pathname: '/', query: { showCategories: true } }}>kategorie</Link></p>
            </div>
        );
    else
        return (
            <LayoutContext.Consumer>
                {({ headerHeight }) => (
                    <div className={css.MainContainer} style={{height: `calc(100vh - ${headerHeight}px)`}}>
                        <Breadcrump items={breadcrumb} onBack={activeStep === 1 ? handleBackToCart : null} historyGoBack />
                        <Grid container className={css.CartContainer}>
                            <Grid item className={css.StepperContainer}>
                                <StepperComponent activeStep={activeStep} />
                            </Grid>
                            <Grid item className={css.StepContainer}>
                                {{
                                    0: <Summary />,
                                    1: <Finalize
                                        newsletter={newsletter}
                                        onFormInitialized={(ref) => setFormRef(ref)}
                                        onCartDiscount={handleCartDiscount}
                                        onDeliveryMethodChange={setDeliveryMethod}
                                        discount={discount}
                                        delivery={deliveryMethod.price}
                                        onNewsletterChange={setNewsletter}
                                        onSubmitForm={handleSubmitForm}
                                        onBackToCart={handleBackToCart}
                                        onDiscardCart={handleDiscardCart}
                                    />
                                }[activeStep]}
                            </Grid>
                            <Grid item className={css.SidebarContainer} style={{height: activeStep === 0 ? '165px' : 0}}>
                                {{
                                    0: <SummarySidebar
                                        onContinue={handleNext}
                                        onDiscardCart={handleDiscardCart}
                                    />
                                }[activeStep]}
                            </Grid>
                        </Grid>
                    </div>
                )}
            </LayoutContext.Consumer>
        )
}

export default Cart;