import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { createContext } from "react";

const LayoutContext = createContext({
    headerHeight: 0,
    setHeaderHeight: () => {},
    blindMode: false,
    setBlindMode: () => {},
});

const LayoutContextProvider = ({ children }) => {
    const [headerHeight, setHeaderHeight] = useState([]);
    const [blindMode, setBlindMode] = useState(false);

    useEffect(() => {
        const header = document.getElementsByTagName("header")[0];
        setHeaderHeight(header.offsetHeight);
    }, []);

    const value = useMemo(
        () => ({
            headerHeight, setHeaderHeight,
            blindMode, setBlindMode,
        }),
        [
            headerHeight, setHeaderHeight,
            blindMode, setBlindMode,
        ]
    );

    return (
        <LayoutContext.Provider value={value}>
            {children}
        </LayoutContext.Provider>
    )
}

export {
    LayoutContext,
    LayoutContextProvider,
};