import React, { Component } from 'react';
import { apiShop } from '../../services/api/shop/Shop';
import ShopBox from './ShopBox';
import css from './Home.module.css';

export class Shops extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shops: [],
      category: props.category ?? null
    }

    this.get();
  }

  async get() {
    let data = await apiShop.getCustom(
      (this.props.category)
        ? `/list/categoryCheck/${this.props.category.id}`
        : '/list'
    );

    if (data && data.shops)
      this.setState({
        shops: data.shops
      });
    else
      this.setState({
        shops: []
      });
  }

  render () {
    return (
      <div key={this.props.category} className={css.TabContainer}>
        {this.state.shops.length > 0 &&
          <div className={css.ShopBoxContainer}>
            {this.state.shops.map((shop, index) => (
                <ShopBox key={index} shop={shop} />
            ))}
          </div>
        }
      </div>
    );
  }
}

