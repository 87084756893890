import { Clear, Search } from "@mui/icons-material";
import SearchIcon from '../../images/icons/search.svg';
import { IconButton, InputAdornment } from "@mui/material";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const SearchButton = ({ active, onClick }) => {
    const {
        resetTranscript,
    } = useSpeechRecognition();

    const handleClick = () => {
        SpeechRecognition.stopListening();
        resetTranscript();

        onClick();
    }

    return (
        <InputAdornment position="start">
            <IconButton onClick={handleClick}>
                {active
                    ? <Clear className="search-icon" />
                    : <img src={SearchIcon} alt=""/>
                }
            </IconButton>
        </InputAdornment>
    );
}

export default SearchButton;