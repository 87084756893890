import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid, IconButton
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

function Breadcrump(props) {
    const {
        items,
        onBack,
        historyGoBack,
    } = props;

    const history = useHistory();

    return (
        <Grid container className="breadcrump">
            {(onBack || historyGoBack) &&
                <IconButton onClick={onBack ?? history.goBack}>
                    <ArrowBack />
                </IconButton>
            }
            {
                items.map((element, index) => {
                    if (index > 0)
                        return <span key={index}>
                                    <span>&nbsp;&gt;&nbsp;</span>
                                    {
                                        items.length !== (index +  1)
                                        ? <Link to={element.url}>{element.text}</Link>
                                        : <span className="last-item">{element.text}</span>
                                    }

                                </span>
                    else
                        return <span key={index}>
                            <Link to={element.url} onClick={element.func}>
                                {element.text}
                            </Link>
                        </span>
                })
            }
        </Grid>
    )
}

Breadcrump.propTypes = {
    items: PropTypes.array.isRequired
};

Breadcrump.defaultProps = {
    items: []
};

export default Breadcrump;