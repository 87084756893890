import React from 'react';
import PropTypes from 'prop-types';
import css from './Product.module.css';
import { Link } from 'react-router-dom';

function Description(props) {
    const {
        text
    } = props;

    return (
        <div className={css.ProductDescription}>
            <h1>Opis</h1>
            {text}
        </div>
    );
}

Description.propTypes = {
    text: PropTypes.string.isRequired
};

export default Description;