import AWS from 'aws-sdk'
import { createContext, useCallback, useMemo, useRef, useState } from 'react';

const Polly = new AWS.Polly({
    credentials: {
        accessKeyId: 'AKIASGIPWIILB7UMRQTG',
        secretAccessKey: '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'
    },
    signatureVersion: 'v4',
    region: 'eu-central-1'
});

const PollySpeakers = {
    pl: 'Ewa',
    en: 'Emma',
    de: 'Vicki',
    es: 'Lucia',
    fr: 'Lea'
};

const PollyContext = createContext({
    readText: () => {},
});

const PollyContextProvider = ({ children }) => {
    const audioRef = useRef(new Audio());

    const readText = useCallback(
        async (text, useSsml = false, onended) => {
            let params = {
                'Text': text,
                'OutputFormat': 'mp3',
                'VoiceId': PollySpeakers['pl'],
            };

            if (useSsml) {
                params['TextType'] = 'ssml';
            }

            Polly.synthesizeSpeech(params, async (err, data) => {
                if (err) {
                    console.error('error', err);
                } else if (data) {
                    var blob = new Blob([data.AudioStream.buffer]);
                    var url = URL.createObjectURL(blob);
                    audioRef.current.pause();
                    audioRef.current = new Audio(url);

                    if (onended) {
                        audioRef.current.onended = onended;
                    }

                    audioRef.current.play();
                }
            })
        },
        [audioRef]
    )

    const value = useMemo(
        () => ({
            readText,
        }),
        [
            readText,
        ]
    );

    return (
        <PollyContext.Provider value={value}>
            {children}
        </PollyContext.Provider>
    )
}

export {
    PollyContext,
    PollyContextProvider,
}