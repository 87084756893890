import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import css from './Cart.module.css';
import _ from 'lodash';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CartContext } from '../../hooks/CartContext';
import { getFirstPropertyValue } from '../../services/common/Objects';

function Summary() {
    const { cart, setSelected, selectAll,
        decrasePosition, incrasePosition, setPositionQuantity,
        remove, removeSelected, removeAll } = useContext(CartContext);

    const getProductText = (count) => {
        if (count > 4)
            return 'przedmiotów';
        else if (count > 1)
            return 'przedmioty';
        else
            return 'przedmiot'
    };

    const getShopLink = () => {
        var shop = cart[0]?.shop;

        return <Link to={`/shop/${shop?.id ?? 0}`}>{shop?.name}</Link>
    };

    const checkIfAllSelected = () => _.every(cart, ['selected', true]);

    return (
        <div className={css.SummaryContainer}>
            <div className={css.Actions}>
                <div>
                    <FormControlLabel control={<Checkbox onClick={() => selectAll()} checked={checkIfAllSelected()} />} label="Cały koszyk" sx={{ '& .MuiTypography-root': { font: 'normal normal normal 1.75em Effra', color: 'var(--header-text-color)' } }} />
                </div>
                <div className="d-flex align-items-center" style={{ gap: '20px' }}>
                    <button onClick={removeSelected}>Usuń zaznaczone</button>
                    <button onClick={removeAll}>Wyczyść koszyk</button>
                </div>
            </div>
            {cart?.length > 0 &&
                <div className={css.Content}>
                    <span className={css.CartContentInfo}>{cart?.length} {getProductText(cart.length)} od {getShopLink()}</span>                    {cart.map((position, yndex) =>
                        <FormControlLabel
                            key={yndex + 100}
                            control={<Checkbox onClick={(event) => setSelected(position, event.target.checked)} checked={position.selected} />}
                            className={css.Line}
                            label={
                                <div className={css.Position}>
                                    <div className="d-flex flex-row w-100" style={{gap: '32px'}}>
                                        <div className={css.Image}>
                                            <img src={`${process.env.REACT_APP_SHOP_IMAGES_SERVER}/${position.product.photoPath}`} alt={position.product.name} />
                                        </div>
                                        <div className={css.ProductInfo}>
                                            <span className={css.Name}>{position.product.name}</span>
                                            <div className={css.QuantityContainer}>
                                                <div className={css.Quantity}>
                                                    <IconButton className={css.Decrase} onClick={() => decrasePosition(position)}><IndeterminateCheckBoxIcon /></IconButton>
                                                    <input type="text" value={position.quantity} onChange={(event) => setPositionQuantity(position, event.target.value)} />
                                                    <IconButton className={css.Incrase} onClick={() => incrasePosition(position)}><AddBoxIcon /></IconButton>
                                                </div>
                                                <div className="d-flex flex-row" style={{gap: '30px'}}>
                                                    <div className={css.ProductPrice}>
                                                        {parseFloat(position.product.grosPrice * position.quantity).toFixed(2).toString().replace('.', ',')} zł
                                                    </div>
                                                    <IconButton className={css.Remove} onClick={() => remove(position)}><DeleteForeverIcon /></IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            sx={{
                                '& .MuiTypography-root': {
                                    width: '100%'
                                }
                            }} />
                    )}
                </div>
            }
        </div>
    );
}

Summary.propTypes = {
}

export default Summary;