import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { apiLogin } from '../../services/api/accounts/Login';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';

import styles from './Login.module.css';
import Breadcrump from '../common/Breadcrump';
import FormField from '../common/FormField';

const LogoutComponent = () => {
    const { register, handleSubmit } = useForm();
    const [validationMessage ] = useState('');

    const breadcrumb = [{
        url: '/',
        text: 'Wylogowywanie',
    }];

    const onSubmit = async (data) => {
        let result = await apiLogin.post(data);

        if (result.error) {
            console.error(result.error);

            window.location.href = "/";
        } else {
            localStorage.removeItem('token', result.token);
            localStorage.removeItem('name', result.name);

            window.location.href = "/";
        }
    };

    return (
        <Container component="main" className={`${styles.LogoutContainer} height100`}>
            <Breadcrump items={breadcrumb} historyGoBack />
            <form onSubmit={handleSubmit(onSubmit)} className={styles.LogoutForm}>
                {validationMessage && <div className="validation-error">{validationMessage}</div>}
                <h1>Wyloguj maszynę</h1>
                <div className="form-floating">
                    <FormField
                        props={{
                            required: true,
                            type: "text",
                            id: "serviceCode",
                            placeholder: "Kod serwisowy",
                        }}
                        capitalized
                        className="form-control"
                        register={register}
                        labelClassName={styles.MessageInputLabel}
                    />
                </div>
                <div className="form-floating">
                    <FormField
                        props={{
                            required: true,
                            type: "password",
                            id: "password",
                            placeholder: "Hasło",
                        }}
                        className="form-control"
                        register={register}
                        labelClassName={styles.MessageInputLabel}
                    />
                </div>
                <button type="submit" className={styles.LoginButton}>WYLOGUJ</button>
            </form>
        </Container>
    );
}

export default LogoutComponent;