import { positions } from 'react-alert'

export const alertOptions = {
    position: positions.MIDDLE
};

export const getAlertConfig = (props) => {
    if (!props)
        props = {};

    let data = {};

    if (props.title)
        data.title = props.title;

    if (props.actions)
        data.actions = props.actions;

    data.closeCopy = (props.closeText) ? props.closeText : 'Ok';
    data.timeout = (props.timeout) ? 3000 : null;

    return data;
}