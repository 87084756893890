import React, { useEffect } from 'react';
import {
    InputAdornment,
    IconButton
} from '@mui/material';
import { Mic, MicNone } from '@mui/icons-material';
import { useAlert } from 'react-alert';
import { getAlertConfig } from '../../configurations/alertOptions';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import useRightClickHold from '../../hooks/RightClickHold';
import beep from './beep.wav';
import { useContext } from 'react';
import { PollyContext } from './Polly';

const SpeechText = ({ onSpeech }) => {
    const {
        transcript,
        listening,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition();
    const { readText } = useContext(PollyContext);
    const alert = useAlert();


    const startListening = () => {
        if (!browserSupportsSpeechRecognition) {
            alert.show('Twoja przeglądarka nie obsługuje funkcji rozpoznawania głosu.', getAlertConfig());
            return;
        }

        SpeechRecognition.startListening();
    }

    const startListeningForBlind = async () => {
        let audio = new Audio(beep);
        await new Promise((resolve) => {
            audio.onended = async () => {
                readText('<speak>Powiedz co chciałbyś zamówić<break time="1s" /></speak>', true, () => {
                    audio.onended = () => {
                        startListening();
                    }
                    audio.play();
                })
                resolve();
            };
            audio.play();
        });
    }

    useRightClickHold(startListeningForBlind, 3000);

    useEffect(() => {
        if (!listening) return;

        onSpeech(transcript);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcript])

    return (
        <InputAdornment position="end">
            {listening
                ? <IconButton onClick={SpeechRecognition.stopListening}>
                    <Mic className="search-icon" />
                </IconButton>
                : <IconButton onClick={startListening}>
                    <MicNone className="search-icon" />
                </IconButton>
            }
        </InputAdornment>
    )
}

export default SpeechText;