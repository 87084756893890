import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Breadcrump from '../common/Breadcrump';
import css from './Search.module.css';
import { apiShop } from '../../services/api/shop/Shop';
import { getTwoPlacesNumberString } from '../../services/common/Numbers';
import { useContext } from 'react';
import { LayoutContext } from '../../hooks/LayoutContext';
import { PollyContext } from '../common/Polly';
import { useRef } from 'react';
import { CartContext } from '../../hooks/CartContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


function Search(props) {
    const {
        phrase,
        onClick,
    } = props;
    const [shops, setShops] = useState([]);
    const [products, setProducts] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [isReading, setIsReading] = useState(false);
    const { blindMode, setBlindMode } = useContext(LayoutContext);
    const { readText } = useContext(PollyContext);
    const { addToCart } = useContext(CartContext);
    const history = useHistory();

    const makeBreadcrumb = (phrase) => {
        return [
          {
            url: '',
            text: <span>Wynik wyszukiwania dla: <strong>{phrase}</strong></span>
          }
        ];
    };



    const loadData = useCallback(
        async () => {
            var data = await apiShop.getCustom(`/list/${phrase}`);
            setShops(data.items);
            setProducts(data.items?.flatMap((shop) => shop.products.map((product) => ({...product, shop}))));

            if (blindMode) {
                if (data.items.length > 0) {
                    readText(`
                        <speak>
                            <p>Aby zamówić pozycję po jej odczytaniu kliknij na przycisk</p>
                            <p>Wyszukiwanie dostępne zawsze po przytrzymaniu klawisza przez 3 sekundy</p>
                        </speak>`,
                        true,
                        () => {
                            setIsReading(true);
                        }
                    );
                } else {
                    readText(`
                        <speak>
                            <p>Brak pozycji - spróbuj wyszukać coś innego.</p>
                            <p>W tym celu przytrzymaj przycisk przez 3 sekundy</p>
                        </speak>`,
                        true,
                        () => {
                            onClick();
                        }
                    );
                }
            }
        },
        [phrase, blindMode, readText]
    );

    const processOrderForBlind = useCallback(async () => {
        const product = products[currentIndex];

        if (!product) return;

        addToCart(product.shop, product, 1);
        onClick();
        history.push('/finalize-blind');
    }, [addToCart, products, currentIndex, history, onClick]);

    const handleListReadingFinished = () => {
        const startReadingAgain = () => {
            clearTimeout(timer);
            setIsReading(true);
        }

        var timer = setTimeout(() => {
            readText(`
                <speak>
                    <break time="1s" />
                    <p>Czy przeczytać jeszcze raz?</p>
                    <p>Jeśli tak kliknij przycisk</p>
                    <break time="3s" />
                </speak>
                `,
                true,
                () => {
                    window.removeEventListener('mousedown', startReadingAgain);
                    readText('Wyłączenie asystenta głosowego');
                    onClick();
                    setBlindMode(false);
                }
            )
        }, 500);

        window.addEventListener('mousedown', startReadingAgain);
    }

    useEffect(
        () => {
            if (!isReading) return;
            const blindProductSelect = async (event) => {
                if (event.which !== 3 || !isReading) return;

                setIsReading(false);
                setCurrentIndex(-1);
                await processOrderForBlind();
            };

            window.addEventListener('mousedown', blindProductSelect);

            return () => {
                window.removeEventListener('mousedown', blindProductSelect);
            }
        },
        [isReading, currentIndex, products, processOrderForBlind]
    )

    useEffect(() => {
        if (isReading && blindMode && currentIndex < products.length) {
            const timer = setTimeout(() => {
                setCurrentIndex((prevIndex) => {
                    readText(
                        products[prevIndex + 1]?.name,
                        false,
                        (prevIndex + 1 === products.length - 1)
                            ? () => {
                                setIsReading(false);
                                setCurrentIndex(-1);
                                handleListReadingFinished();
                            }
                            : null
                    );

                    return prevIndex + 1;
                });
            }, 3000);

            return () => clearTimeout(timer);
        }

    }, [isReading, currentIndex, products, blindMode, readText]);

    useEffect(
        () => {
            async function fetchData() {
                await loadData();
            }

            fetchData();
        },
        [phrase, loadData]
    );

    return(
        <div className="h-100 d-flex flex-column">
            <Breadcrump items={makeBreadcrumb(phrase)} onBack={onClick} />
            <div className={css.SearchContainer}>
                {shops.map((shop, index) =>
                    <div key={index} className={css.Shop}>
                        <h1>{shop.name}</h1>
                        <div className={css.ProductsContainer}>
                            {shop.products.map((product, index) =>
                                <Link key={index} to={`/product/${product.id}`} onClick={onClick} className={`${css.Product} ${product.id === products[currentIndex]?.id ? css.ProductActive : ''}`}>
                                    <div className={css.ShopLogo}>
                                        <img src={`${process.env.REACT_APP_ADMIN_IMAGES_SERVER}/${shop.logoPath}`} alt={shop.name} />
                                    </div>
                                    <div className={css.ProductInfo}>
                                        <div className={css.ProductImage}>
                                            <img src={`${process.env.REACT_APP_SHOP_IMAGES_SERVER}/${product.photoPath}`} alt={product.name} />
                                        </div>
                                        <div className={css.ProductInfoContainer}>
                                            <h6 className={css.ProductName}>{product.name}</h6>
                                            <span className={css.ProductPrice}>{getTwoPlacesNumberString(product.grosPrice)} zł</span>
                                        </div>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

Search.propTypes = {
    phrase: PropTypes.string.isRequired
};

export default Search;