import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepLabel } from '@mui/material';
import Summary from './Summary';

const steps = [
    {
        label: 'Twój koszyk',
        component: <Summary />,
        sidebar: null
    },
    {
        label: 'Dostawa i płatność',
        component: null,
        sidebar: null
    },
    {
        label: 'Gotowe',
        component: null,
        sidebar: null
    },
];

function StepperComponent(props) {
    const {
        activeStep
    } = props;

    return (
        <Stepper activeStep={activeStep}>
            {steps.map((step, index) => {
                return (
                    <Step key={index}>
                        <StepLabel sx={{
                            '& .MuiSvgIcon-root, & .MuiStepLabel-label': {
                                font: 'normal normal normal 1.9rem Effra;',
                                color: 'var(--ribbon-text-color) !important',
                            },
                            '& .Mui-active, & .Mui-completed': {
                                fontWeight: 'bold',
                                color: 'var(--accent-text-color) !important'
                            },
                            '& .Mui-active text': {
                                fontWeight: 'bold',
                                fill: 'var(--accent-button-text) !important'
                            },
                            '& .MuiStepLabel-iconContainer svg': {
                                fontSize: '2rem',
                            }
                        }}>
                            {step.label}
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    )
}

StepperComponent.propTypes = {
    activeStep: PropTypes.number.isRequired
}

export default StepperComponent;