import ApiCore from '../utilities/ApiCore';

export const apiLogin = new ApiCore({
    get: false,
    getSingle: false,
    search: false,
    post: true,
    put: false,
    delete: false,
    url: 'login',
    plural: 'login',
    single: 'login'
});