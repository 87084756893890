import React, { Component } from 'react';
import {
  Button, Container, Grid
} from '@mui/material';
import Breadcrump from '../common/Breadcrump';

import css from './Home.module.css';
import { Categories } from './Categories';
import { Shops } from './Shops';
import { TabPanel } from '../common/Controls';
import { withRouter } from 'react-router-dom';
import { apiShop } from '../../services/api/shop/Shop';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();

    this.handleCategorySelect = this.handleCategorySelect.bind(this);
    this.makeBreadcrumb = this.makeBreadcrumb.bind(this);
    this.getDefaultState = this.getDefaultState.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  getDefaultState() {
    let tab = this.props.location.search.indexOf("allShops") > 0 || !this.props.location.query?.showCategories ? 1 : 0;

    if (this.props.location.search.indexOf('categories') > 0) {
      tab = 0;
    }

    return {
      selectedTab: tab,
      selectedCategory: null,
      breadcrumb: this.makeBreadcrumb(tab, null),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.setState(this.getDefaultState());
    }
  }

  makeBreadcrumb(tab, category, selectedCategory) {
    var breadcrumb = [
      {
        url: `/${selectedCategory ? '?categories' : ''}`,
        func: selectedCategory ? () => this.handleCategorySelect(null, 0) : null,
        text: tab === 0 || selectedCategory ? 'Kategorie sklepów' : 'Wszystkie sklepy'
      }
    ];

    if (category)
      breadcrumb.push({
        url: null,
        text: category.name
      });

    return breadcrumb;
  }

  selectTab(tab) {
    this.setState({
      selectedTab: tab,
      breadcrumb: this.makeBreadcrumb(tab, null, this.state.selectedCategory),
    })
  }

  checkActive(tab) {
    return this.state.selectedTab === tab;
  }

  async getShops() {
    let data = await apiShop.getCustom((this.state.selectedCategory) ? `/list/categoryCheck/${this.state.selectedCategory.id}` : '/list');

    if (data && data.shops)
      this.setState({
        shops: data.shops
      });
    else
      this.setState({
        shops: []
      });
  }

  handleCategorySelect(category, tab) {
    let correctTab = isNaN(tab) ? 1 : tab;
    this.setState({
      selectedCategory: category,
      selectedTab: correctTab,
      breadcrumb: this.makeBreadcrumb(correctTab, category, category),
    });
  }

  handleBack() {
    this.handleCategorySelect(null, 0);
  }

  render () {
    return (
      <div className="h-100 d-flex flex-column" style={{backgroundColor: 'var(--main-bg-color)'}}>
        <div className={css.TabsContainer}>
          <Breadcrump items={this.state.breadcrumb} onBack={this.state.selectedCategory ? this.handleBack : null}/>
          {!this.state.selectedCategory &&
            <Container className={`${css.TabButtonsContainer} mt-4`}>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <Button className={`${css.TabButton} ${css.TabButtonLeft} ${(this.checkActive(0)) ? css.TabButtonActive : ''}`} onClick={() => this.selectTab(0)}>Kategorie sklepów</Button>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Button className={`${css.TabButton} ${css.TabButtonRight} ${(this.checkActive(1)) ? css.TabButtonActive : ''}`} onClick={() => this.selectTab(1)}>Wszystkie sklepy</Button>
                </Grid>
              </Grid>
            </Container>
          }
        </div>
        <TabPanel value={this.state.selectedTab} index={0}>
          <Categories onCategorySelect={this.handleCategorySelect}/>
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={1}>
          <Shops selected={this.state.selectedTab} category={this.state.selectedCategory} shops={this.state.shops} />
        </TabPanel>
      </div>
    );
  }
}

export default withRouter(Home);