import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import css from './Shop.module.css';
import { IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Link } from 'react-router-dom';
import { getTwoPlacesNumberString } from '../../services/common/Numbers';
import { PollyContext } from '../common/Polly';

function Products(props) {
    const {
        items
    } = props;
    const { readText } = useContext(PollyContext);

    const readProduct = async (event, productName) => {
        event.preventDefault();

        await readText(productName);
    }

    return (
        <div className={css.ProductsContainer}>
            <h6 className={css.SectionTitle}>Produkty</h6>
            {
                items.length === 0
                ? <span className={css.ProductsNotFound}>Nie znaleziono produktów w wybranej kategorii.</span>
                :
                <div className={css.ProductBoxesContainer}>
                {items.map((product, index) =>
                    <Link key={index} to={`/product/${product.id}`} className={css.ProductBox}>
                        <IconButton
                            className={css.SpeakerButton}
                            onClick={(event) => readProduct(event, product.name)}
                        >
                            <VolumeUpIcon />
                        </IconButton>
                        <div>
                            <div className={css.ProductImage}>
                                <img src={`${process.env.REACT_APP_SHOP_IMAGES_SERVER}/${product.photoPath}`} alt={product.name} />
                            </div>
                            <h6 className={css.ProductName}>{product.name}</h6>
                        </div>
                        <span className={css.ProductPrice}>{getTwoPlacesNumberString(product.grosPrice)} zł</span>
                    </Link>
                )}
                </div>
            }
        </div>
    )
}

Products.propTypes = {
    items: PropTypes.array.isRequired
};

Products.defaultProps = {
    items: []
};

export default Products;