export const handleResponse = (response) => {
    if (response.results) {
        return response.results;
    }

    if (response.data) {
        return response.data;
    }

    return response;
}

export const handleError = (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        localStorage.removeItem('token');
        localStorage.removeItem('fullName');
        localStorage.removeItem('role');
        localStorage.removeItem('email');
        localStorage.removeItem('avatar');

        window.location.reload(false);
    }

    if (error.data) {
        return error.data;
    }
    return error;
}