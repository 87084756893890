import React, { Component } from 'react';
import Breadcrump from '../common/Breadcrump';
import { apiShop } from '../../services/api/shop/Shop';
import { apiProduct } from '../../services/api/shop/Product';
import css from './Shop.module.css';
import Categories from './Categories';
import Products from './Products';
import Details from './Details';

export class Shop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shop: null,
            categories: [],
            products: [],
            selectedCategory: 0,
            breadcrumb: this.makeBreadcrumb('')
        };

        this.get(props.match.params.id);

        this.handleCategorySelected = this.handleCategorySelected.bind(this);
    }

    makeBreadcrumb(name) {
        return [
          {
            url: '/?allShops',
            text: 'Wszystkie sklepy'
          },
          {
            url: null,
            text: name
          }
        ];
    }

    async get(id) {
        let data = await apiShop.getSingle(id);

        if (data && data.shop) {
            this.setState({
                shop: data.shop,
                categories: data.categories,
                breadcrumb: this.makeBreadcrumb(data.shop.name)
            });

            if (data.categories.length > 0)
                this.handleCategorySelected(data.categories[0].id);
        }
    }

    async getProducts(categoryId) {
        let data = await apiProduct.getCustom(`/by-category/${categoryId}`);

        if (data && data.products) {
            this.setState({
                products: data.products
            });
        }
    }

    async handleCategorySelected(id) {
        await this.getProducts(id);

        this.setState({
            selectedCategory: id
        });
    }

    render() {
        return (
            <div className={css.MainContainer}>
                <Breadcrump items={this.state.breadcrumb} historyGoBack />
                <div className={css.ShopContainer}>
                    <Categories items={this.state.categories} selectedCategory={this.state.selectedCategory} onCategorySelected={this.handleCategorySelected} />
                    <Products items={this.state.products} />
                    {this.state.shop &&
                        <Details data={this.state.shop} />
                    }
                </div>
            </div>
        );
    }
}