import React, { useContext } from 'react';
import css from './Home.module.css';
import { IconButton } from '@mui/material';
import { Phone, VolumeUp } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { PollyContext } from '../common/Polly';

const ShopBox = ({ shop }) => {
    const { readText } = useContext(PollyContext);

    const handleRead = async (event) => {
        event.preventDefault();

        await readText(shop.name);
    }

    return (
        <Link to={`/shop/${shop.id}`} className={css.ShopBox}>
            <IconButton
                className={css.SpeakerButton}
                onClick={handleRead}
            >
                <VolumeUp />
            </IconButton>
            <div className={css.ShopLogo}>
                <img src={`${process.env.REACT_APP_ADMIN_IMAGES_SERVER}/${shop.logoPath}`} alt={shop.name} />
            </div>
            <div className={css.ShopInfo}>
                <div className={css.ShopTitle}>
                    <h6>{shop.name}</h6>
                    <span>Poziom 0</span>
                </div>
                <span className={css.ShopPhoneNumber}>
                    <Phone />
                    {shop.phoneNumber}
                </span>
            </div>
        </Link>
    );
}

export default ShopBox;