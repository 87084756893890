import { useContext, useEffect, useRef } from 'react';
import { LayoutContext } from './LayoutContext';

const useRightClickHold = (callback, holdTime = 5000) => {
    const timeoutRef = useRef(null);
    const { setBlindMode } = useContext(LayoutContext);

    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        const handleMouseDown = (event) => {
            if (event.which !== 3) return;

            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                setBlindMode(true);
                callback();
            }, holdTime);
        };

        const handleMouseUp = (event) => {
            clearTimeout(timeoutRef.current);
        };

        window.addEventListener('contextmenu', handleContextMenu);
        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('contextmenu', handleContextMenu);
            window.addEventListener('mousedown', handleMouseDown);
            window.removeEventListener('mouseup', handleMouseUp);
            clearTimeout(timeoutRef.current);
        };
    }, [callback, holdTime]);
};

export default useRightClickHold;