import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useAlert } from 'react-alert';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { deliveryMethods } from '../../configurations/deliveryMethods';
import { paymentMethods } from '../../configurations/paymentMethods';
import { getAlertConfig } from '../../configurations/alertOptions';
import { apiOrder } from '../../services/api/order/Order';
import { apiCoupon } from '../../services/api/coupon/Coupon';
import { CartContext } from '../../hooks/CartContext';
import css from './Cart.module.css';
import FinalizeCartPosition from './FinalizeCartPosition';
import FinalizeDeliverySection from './FinalizeDeliverySection';
import FinalizeDeliveryData from './FinalizeDeliveryData';
import FinalizeChooseShippingNotify from './FinalizeChooseShippingNotify';
import FinalizeCoupon from './FinalizeCoupon';
import FormField from '../common/FormField';
import FinalizeSidebar from './FinalizeSidebar';

function Finalize(props) {
    const {
        newsletter,
        onCartDiscount,
        onFormInitialized,
        onDeliveryMethodChange,
        discount,
        delivery,
        onNewsletterChange,
        onSubmitForm,
        onBackToCart,
        onDiscardCart,
    } = props;

    const alert = useAlert();
    const { register, handleSubmit } = useForm();
    const { cart, setPositionMessage, setPositionDiscount, removeAll } = useContext(CartContext);

    const [deliveryMethod, setDeliveryMethod] = useState(deliveryMethods[0]);
    const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0]);
    const [wantNotifications, setWantNotifications] = useState(false);
    const [wantInvoice, setWantInvoice] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const [coupon, setCoupon] = useState(null);

    const handleDeliveryMethodChange = (method) => {
        setDeliveryMethod(method);
        onDeliveryMethodChange(method);
    }

    const verifyCoupon = async () => {
        var res = await apiCoupon.getCustom(`/verify/${couponCode}`);

        if (res.valid) {
            if (!checkIfCartHaveCouponProducts(res.coupon)) {
                alert.show('Twój koszyk nie zawiera produktu, którego dotyczy kod rabatowy.', getAlertConfig());
                return;
            }

            redeemCoupon(res.coupon);
        } else {
            alert.show('Wprowadzony kod jest nieaktywny lub stracił ważność.', getAlertConfig());
        }
    }

    const checkIfCartHaveCouponProducts = (coupon) => {
        if (coupon.type === 1 || coupon.type === 3) {
            return true;
        }

        return _.some(cart, (pos) => !!(_.find(coupon.products, (p) => p.productId === pos.product.id)));
    }

    const redeemCoupon = (coupon) => {
        if (!coupon)
            return;

        setCoupon(coupon);

        switch (coupon.type) {
            case 0:
                setPositionDiscount(coupon.products, coupon.discountAmount, true);
                break;
            case 1:
                onCartDiscount(coupon.discountAmount, true);
                break;
            case 2:
                setPositionDiscount(coupon.products, coupon.discountAmount, false);
                break;
            case 3:
                onCartDiscount(coupon.discountAmount, false);
                break;
            default:
                break;
        }

        alert.show('Przyznano rabat na podstawie kodu rabatowego.', getAlertConfig());
    }

    const handleFormSubmit = async (data) => {
        data.items = cart.map((item) => {
            console.log('item :>> ', item);
            return {
                productId: item.product.id,
                shopId: item.shop.id,
                price: item.product.grosPrice,
                quantity: item.quantity,
                message: item.message
            }
        });
        data.couponId = coupon?.id;
        data.deliveryMethod = deliveryMethod.id;
        data.paymentMethod = paymentMethod.id;
        data.wantNotifications = wantNotifications;
        data.wantInvoice = wantInvoice;
        data.newsletter = newsletter;

        var res = await apiOrder.post(data);

        if (res.id) {
            removeAll();
            window.location.href = `/thanks/${res.id}`;
        } else {
            console.error(res);
        }
    }

    const requestFormSubmit = async (data) => {
        await alert.show("Czy potwierdzasz zamówienie?", getAlertConfig({
            closeText: 'Anuluj',
            actions: [
                {
                    copy: 'Potwierdź',
                    onClick: () => handleFormSubmit(data),
                }
            ]
        }));
    }

    return (
        <div className={css.FinalizeContainer}>
            {cart.filter((pos) => pos.selected).map((pos, index) => (
                <FinalizeCartPosition
                    key={index}
                    position={pos}
                    onMessageChange={setPositionMessage}
                />
            ))}
            <FinalizeDeliverySection
                selected={deliveryMethod}
                onChange={handleDeliveryMethodChange}
            />
            <form ref={ref => onFormInitialized(ref)} className={css.Form} onSubmit={handleSubmit(requestFormSubmit)}>
                <FinalizeDeliveryData
                    show={deliveryMethod?.needAddress === true}
                    fieldRegistration={register}
                />
                <FinalizeChooseShippingNotify
                    checked={wantNotifications}
                    fieldRegistration={register}
                    onChange={() => setWantNotifications(!wantNotifications)}
                />
                <FinalizeCoupon
                    fieldRegistration={register}
                    onChange={setCouponCode}
                    onVerify={verifyCoupon}
                />
                <div className={css.PaymentMethodContainer}>
                    <span className={css.CardTitle}>Metody płatności</span>
                    <div className="d-flex flex-row" style={{marginTop: '30px'}}>
                        {paymentMethods.map((method, index) =>
                            <div
                                key={index}
                                className={`${css.PaymentMethodBox} ${(paymentMethod === method) ? css.Active : ''}`}
                                onClick={() => setPaymentMethod(method)}

                            >
                                <span className={css.Title}>{method.name}</span>
                                <div className={css.PaymentIconContainer}>
                                    <img src={method.icon} alt={method.name} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-100 mt-5 mb-3">
                    <span className={css.CardTitle}>Faktura</span>
                </div>
                <Grid container spacing={2} className="mb-2">
                    <Grid item md={12}>
                        <FormControlLabel
                            control={<Checkbox onClick={() => setWantInvoice(!wantInvoice)} checked={wantInvoice} />}
                            label={
                                <div className="d-flex flex-row w-100 align-items-center">
                                    Chcę otrzymać fakturę od sprzedawcy
                                </div>
                            }
                            className="w-100 big-checkbox-label"
                            sx={{
                                    '&': {
                                        padding: 0,
                                        margin: 0,
                                        gap: '20px'
                                    },
                                    '& .MuiCheckbox-root': {
                                        padding: 0,
                                    },
                            }} />
                    </Grid>
                </Grid>
                {wantInvoice &&
                <Grid container spacing={2} className="mb-5">
                    <Grid item md={6} className="form-floating">
                        <FormField
                            props={{
                                required: true,
                                type: "text",
                                id: "nip",
                                placeholder: "NIP",
                            }}
                            capitalized
                            className="form-control"
                            register={register}
                            labelClassName={css.MessageInputLabel}
                        />
                    </Grid>
                    <Grid item md={6}>
                        {/* <button className={`${css.ContinueCart} ${css.GUSButton}`}>Pobierz dane z GUS</button> */}
                    </Grid>
                    <Grid item md={6} className="form-floating">
                        <FormField
                            props={{
                                required: true,
                                type: "text",
                                id: "companyName",
                                placeholder: "Nazwa firmy",
                            }}
                            capitalized
                            className="form-control"
                            register={register}
                            labelClassName={css.MessageInputLabel}
                        />
                    </Grid>
                    <Grid item md={6} className="form-floating">
                        <FormField
                            props={{
                                required: true,
                                type: "text",
                                id: "companyStreet",
                                placeholder: "Nazwa ulicy",
                            }}
                            capitalized
                            className="form-control"
                            register={register}
                            labelClassName={css.MessageInputLabel}
                        />
                    </Grid>
                    <Grid item md={6} className="form-floating">
                        <FormField
                            props={{
                                required: true,
                                type: "text",
                                id: "companyHouseNumber",
                                placeholder: "Numeru domu",
                            }}
                            capitalized
                            className="form-control"
                            register={register}
                            labelClassName={css.MessageInputLabel}
                        />
                    </Grid>
                    <Grid item md={6} className="form-floating">
                        <FormField
                            props={{
                                required: false,
                                type: "text",
                                id: "companyDoorsNumber",
                                placeholder: "Numer lokalu",
                            }}
                            capitalized
                            className="form-control"
                            register={register}
                            labelClassName={css.MessageInputLabel}
                        />
                    </Grid>
                    <Grid item md={6} className="form-floating">
                        <FormField
                            props={{
                                required: true,
                                type: "text",
                                id: "companyPostalCode",
                                placeholder: "Kod pocztowy",
                            }}
                            capitalized
                            className="form-control"
                            register={register}
                            labelClassName={css.MessageInputLabel}
                        />
                    </Grid>
                    <Grid item md={6} className="form-floating">
                        <FormField
                            props={{
                                required: true,
                                type: "text",
                                id: "companyCity",
                                placeholder: "Miasto",
                            }}
                            capitalized
                            className="form-control"
                            register={register}
                            labelClassName={css.MessageInputLabel}
                        />
                    </Grid>
                    <Grid item md={12} className="form-floating">
                        <FormField
                            props={{
                                required: true,
                                type: "text",
                                id: "companyEmail",
                                placeholder: "Twój adres email",
                            }}
                            capitalized
                            className="form-control"
                            register={register}
                            labelClassName={css.MessageInputLabel}
                        />
                    </Grid>
                </Grid>
                }
            </form>
            <FinalizeSidebar
                discount={discount}
                delivery={deliveryMethod.price}
                onNewsletterChange={onNewsletterChange}
                onSubmitForm={onSubmitForm}
                onBackToCart={onBackToCart}
                onDiscardCart={onDiscardCart}
            />
        </div>
    );


}

Finalize.propTypes = {
    onCartDiscount: PropTypes.func.isRequired,
    onFormInitialized: PropTypes.func.isRequired,
    onDeliveryMethodChange: PropTypes.func.isRequired
}

export default Finalize;