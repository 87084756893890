import blik from '../images/icons/blik.png';
import card from '../images/icons/card.png';

export const paymentMethods = [
    {
        id: 0,
        name: 'BLIK',
        icon: blik
    },
    {
        id: 1,
        name: 'Karta kredytowa',
        icon: card
    }
]