import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import css from './Product.module.css';
import { Grid, Button } from '@mui/material';
import ConfirmDialog from './ConfirmDialog';
import { CartContext } from '../../hooks/CartContext';
import { useHistory } from 'react-router-dom';

function AddToCart(props) {
    const {
        shop,
        product,
        quantity
    } = props;

    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
    const { addToCart } = useContext(CartContext);
    const history = useHistory();

    const handleAddToCart = async () => {
        await addToCart(shop, product, quantity);
        setConfirmDialogOpen(true);
    }

    const handleConfirmDialogClose = (result) => {
        setConfirmDialogOpen(false);
    }

    const handleGoBack = () => {
        history.goBack();
    }

    return (
        <Grid item md={12} className={css.AddToCartContainer}>
            {/* <Button className={css.GoBack} onClick={handleGoBack}>POWRÓT</Button> */}
            {/* <div className={css.RightSide}> */}
                <div className={css.Summary}>
                    <span className={css.Title}>
                        Cena za całość
                    </span>
                    <span className={css.Price}>
                        {parseFloat(product.grosPrice * quantity).toFixed(2).toString().replace('.', ',')} zł
                    </span>
                </div>
                <Button className={css.AddToCart} onClick={handleAddToCart}>DODAJ DO KOSZYKA</Button>
            {/* </div> */}
            <ConfirmDialog product={product} quantity={quantity} open={confirmDialogOpen} onClose={handleConfirmDialogClose} />
        </Grid>
    );
}

AddToCart.propTypes = {
    shop: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    quantity: PropTypes.number.isRequired
};

export default AddToCart;