import React from 'react';
import PropTypes from 'prop-types';
import css from './Product.module.css';
import { Dialog, Grid, Button, DialogActions, DialogTitle, DialogContent, IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Link } from 'react-router-dom';

function ConfirmDialog(props) {
    const {
        product,
        quantity,
        open,
        onClose
    } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} className={css.ConfirmDialog} fullWidth sx={{'& .MuiDialog-paper': {maxWidth: '700px', borderRadius: '10px', position: 'relative', display: 'flex', alignItems: 'center'}}}>
            <DialogTitle className={css.Title}>Dodałeś przedmiot do koszyka</DialogTitle>
            <DialogContent className={css.Content}>
                <div className={css.ProductBox}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div className={css.ProductImage}>
                            <img src={`${process.env.REACT_APP_SHOP_IMAGES_SERVER}/${product.photoPath}`} alt={product.name} />
                        </div>
                        <h6 className={css.ProductName}>{product.name}</h6>
                    </div>
                    <span className={css.ProductPrice}>{parseFloat(product.grosPrice * quantity).toFixed(2).toString().replace('.', ',')} zł</span>
                </div>
            </DialogContent>
            <DialogActions className={css.Actions}>
                <Link to="/cart" autoFocus className={css.TakeOrder}>ZŁÓŻ ZAMÓWIENIE</Link>
                <span onClick={handleClose} className={css.ContinueShopping}>KONTYNUUJ ZAKUPY</span>
            </DialogActions>
        </Dialog>
    );
}

ConfirmDialog.propTypes = {
    product: PropTypes.object.isRequired,
    quantity: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default ConfirmDialog;