import React, {  } from 'react';
import { Grid } from '@mui/material';
import css from './Cart.module.css';
import FormField from '../common/FormField';

function FinalizeDeliveryData({ show, fieldRegistration }) {
    if (!show) return <></>;

    return (
        <div className={css.DeliveryDataContainer}>
            <div className="w-100 mt-5" style={{marginBottom: '30px'}}>
                <span className={css.CardTitle}>Dane dostawy</span>
            </div>
            <Grid container spacing={2}>
                <Grid item md={6} className="form-floating">
                    <FormField
                        props={{
                            required: true,
                            type: "text",
                            id: "name",
                            placeholder: "Imię",
                        }}
                        capitalized
                        className="form-control"
                        register={fieldRegistration}
                        labelClassName={css.MessageInputLabel}
                    />
                </Grid>
                <Grid item md={6} className="form-floating">
                    <FormField
                        props={{
                            required: true,
                            type: "text",
                            id: "surname",
                            placeholder: "Nazwisko",
                        }}
                        capitalized
                        className="form-control"
                        register={fieldRegistration}
                        labelClassName={css.MessageInputLabel}
                    />
                </Grid>
                <Grid item md={6} className="form-floating">
                    <FormField
                        props={{
                            required: true,
                            type: "text",
                            id: "street",
                            placeholder: "Nazwa ulicy",
                        }}
                        capitalized
                        className="form-control"
                        register={fieldRegistration}
                        labelClassName={css.MessageInputLabel}
                    />
                </Grid>
                <Grid item md={3} className="form-floating">
                    <FormField
                        props={{
                            required: true,
                            type: "text",
                            id: "houseNumber",
                            placeholder: "Numer domu",
                        }}
                        className="form-control"
                        register={fieldRegistration}
                        labelClassName={css.MessageInputLabel}
                    />
                </Grid>
                <Grid item md={3} className="form-floating">
                    <FormField
                        props={{
                            required: false,
                            type: "text",
                            id: "doorsNumber",
                            placeholder: "Numer lokalu",
                        }}
                        className="form-control"
                        register={fieldRegistration}
                        labelClassName={css.MessageInputLabel}
                    />
                </Grid>
                <Grid item md={6} className="form-floating">
                    <FormField
                        props={{
                            required: true,
                            type: "text",
                            id: "postalCode",
                            placeholder: "Kod pocztowy",
                        }}
                        className="form-control"
                        register={fieldRegistration}
                        labelClassName={css.MessageInputLabel}
                    />
                </Grid>
                <Grid item md={6} className="form-floating">
                    <FormField
                        props={{
                            required: true,
                            type: "text",
                            id: "city",
                            placeholder: "Miasto",
                        }}
                        capitalized
                        className="form-control"
                        register={fieldRegistration}
                        labelClassName={css.MessageInputLabel}
                    />
                </Grid>
                <Grid item md={6} className="form-floating">
                    <FormField
                        props={{
                            required: true,
                            type: "text",
                            id: "phoneNumber",
                            placeholder: "Numer telefonu",
                        }}
                        className="form-control"
                        register={fieldRegistration}
                        labelClassName={css.MessageInputLabel}
                    />
                </Grid>
                <Grid item md={6} className="form-floating">
                    <FormField
                        props={{
                            required: true,
                            type: "text",
                            id: "email",
                            placeholder: "Twój adres email",
                        }}
                        className="form-control"
                        register={fieldRegistration}
                        labelClassName={css.MessageInputLabel}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default FinalizeDeliveryData;