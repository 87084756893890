import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { VolumeUp } from '@mui/icons-material';
import { PollyContext } from '../common/Polly';
import css from './Shop.module.css';

function Categories(props) {
    const {
        items,
        selectedCategory,
        onCategorySelected
    } = props;
    const { readText } = useContext(PollyContext);

    const readCategory = async (event, productName) => {
        event.stopPropagation();

        await readText(productName);
    }

    return (
        <div className={css.CategoriesContainer}>
            <h6 className={css.SectionTitle}>Kategorie</h6>
            <div className={css.CategoryBoxContainer}>
            {items.map((category, index) => (
                <div
                    key={index}
                    className={`${css.CategoryBox} ${(selectedCategory === category.id) ? css.Active : ''}`}
                    onClick={async () => await onCategorySelected(category.id)}
                >
                    <IconButton
                        className={css.SpeakerButton}
                        onClick={(event) => readCategory(event, category.name)}
                    >
                        <VolumeUp />
                    </IconButton>
                    <div className={css.CategoryImage}>
                        <img src={`${process.env.REACT_APP_SHOP_IMAGES_SERVER}/${category.iconPath}`} alt={category.name} />
                    </div>
                    <h6 className={css.CategoryName}>{category.name}</h6>
                </div>
            ))}
            </div>
        </div>
    );
}

Categories.propTypes = {
    items: PropTypes.array.isRequired,
    selectedCategory: PropTypes.number.isRequired,
    onCategorySelect: PropTypes.func.isRequired
};

Categories.defaultProps = {
    items: [],
    selectedCategory: 0,
    onCategorySelect: () => { return; }
};

export default Categories;