const numberWithTwoPlaces = (number) => {
    return parseFloat(number).toFixed(2);
}

const getTwoPlacesNumberString = (number) => getNumberString(numberWithTwoPlaces(number));

const getNumberString = (number) => {
    return number.toString().replace('.', ',');
}

export {
    numberWithTwoPlaces,
    getNumberString,
    getTwoPlacesNumberString
}