import ApiCore from '../utilities/ApiCore';

export const apiProduct = new ApiCore({
    get: false,
    getSingle: true,
    getList: true,
    getCustom: true,
    search: false,
    url: 'shop/product',
    plural: 'shop/product',
    single: 'shop/product'
});