import { Checkbox, FormControlLabel } from '@mui/material';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import QRCode from "react-qr-code";
import Breadcrump from '../common/Breadcrump';
import css from './Thanks.module.css';
import { withAlert } from 'react-alert';
import { getAlertConfig } from '../../configurations/alertOptions';
import { apiOrder } from '../../services/api/order/Order'
class Thanks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            wantNotifications: false,
            email: '',
            breadcrumb: [{
                url: null,
                text: 'Zakupy gotowe'
            }]
        };

        this.getOrderNumber().then(({ number }) => this.setState({ number }));

        this.getOrderNumber = this.getOrderNumber.bind(this);
        this.getAlertActions = this.getAlertActions.bind(this);
        this.handlePaymentFinalize = this.handlePaymentFinalize.bind(this);
    }

    async getOrderNumber() {
        return await apiOrder.getCustom(`/number/${this.props.match.params.id}`);
    }

    getAlertActions() {
        return [
            {
                copy: "Tak",
                onClick: this.handlePaymentFinalize
            }
        ];
    }

    async handlePaymentFinalize() {
        await apiOrder.getCustom(`/finalize/${this.state.id}`);

        this.props.alert?.show("Zamówienie zostało pomyślnie oznaczone jako opłacone i w trakcie realizacji.", getAlertConfig());
    }

    render() {
        if (!this.state.number)
            return <></>;

        // this.props.alert?.show('Czy oznaczyć zamówienie jako opłacone i w trakcie realizacji? (w celach testowych)', getAlertConfig({
        //     closeText: 'Nie',
        //     actions: this.getAlertActions(),
        //     timeout: false
        // }));

        return (
            <div className={css.MainContainer}>
                <Breadcrump items={this.state.breadcrumb} />
                <div className={css.Container}>
                    <div className={css.Message}>
                        <h1>Dziękujemy za zakupy</h1>
                        <h3>{this.state.number}</h3>
                        <p>Twoja przesyłka niebawem do Ciebie wyruszy. Fakturę otrzymasz na podany adres e-mail</p>
                    </div>
                    <div className={css.NotificationsContainer}>
                        <div className={css.Notifications}>
                            <p>Powiadomienia o przesyłce</p>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.wantNotifications} onChange={() => this.setState({wantNotifications: !this.state.wantNotifications})} />}
                                label={
                                    <div className="d-flex flex-row w-100 align-items-center">
                                        Chcę otrzymywać powiadomienia e-mail
                                    </div>
                                }
                                className="w-100 mb-3 big-checkbox-label" />
                            <div className="form-floating">
                                <input type="email" className={`form-control ${css.MessageInput}`} placeholder="Twój adres email" disabled={!this.state.wantNotifications}
                                        value={this.state.email} onChange={(event) => this.setState({email: event.target.value})}/>
                                <label className={css.MessageInputLabel}>Twój adres email</label>
                            </div>
                        </div>
                        <div className={css.TrackOrder}>
                            <p>Śledź status swojego zamówienia</p>
                            <div style={{ height: 'auto', maxWidth: '130px', width: '100%' }}>
                                <QRCode
                                    size={256}
                                    bgColor="#F0F2F4"
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={`${window.location.origin}/orderStatus/${this.state.id}`}
                                    viewBox={`0 0 256 256`}
                                    bg
                                />
                            </div>
                        </div>
                    </div>
                    <div className={css.ButtonContainer}>
                        <Link to="/" onClick={() => alert('cos')} className={css.Button}>WRÓĆ NA STRONĘ GŁÓWNĄ</Link>
                    </div>
                </div>
            </div>
        );
    };
}

export default withAlert()(Thanks);