import React, {  } from 'react';
import { Grid } from '@mui/material';
import css from './Cart.module.css';
import FormField from '../common/FormField';

function FinalizeCoupon({ fieldRegistration, onChange, onVerify }) {
    return (
        <>
            <div className="w-100 mt-5" style={{marginBottom: '30px'}}>
                <span className={css.CardTitle}>Płać mniej</span>
            </div>
            <Grid container spacing={2}>
                <Grid item md={12} className="form-floating d-flex">
                    <FormField
                        props={{
                            required: false,
                            type: "text",
                            id: "discountCode",
                            placeholder: "Podaj kod rabatowy",
                        }}
                        className={`form-control ${css.MessageInput} ${css.Coupon}`}
                        register={fieldRegistration}
                        labelClassName={css.MessageInputLabel}
                        onChange={(event) => onChange(event.target.value)}
                    />
                    <span className={css.CouponButton} onClick={onVerify}>Wykorzystaj</span>
                </Grid>
            </Grid>
        </>
    );
}

export default FinalizeCoupon;