import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { confirm } from 'react-confirm-box';
import { confirmOptions } from '../services/common/Options';
import { numberWithTwoPlaces } from '../services/common/Numbers';

const CartContext = createContext({
    cart: [],
    setCart: () => {},
    addToCart: (shop, product, quantity) => {},
    remove: (position) => {},
    removeSelected: () => {},
    removeAll: () => {},
    setSelected: (position, isSelected) => {},
    selectAll: () => {},
    decrasePosition: (position) => {},
    incrasePosition: (position) => {},
    setPositionQuantity: (position, quantity) => {},
    getCartAmount: () => {},
    setPositionMessage: (position, message) => {},
    setPositionDiscount: (products, discount, isPercentage) => {},
    getCartDiscount: () => {},
    clearDiscount: () => {}
});

const CartContextProvider = ({ children }) => {
    const [cart, setCart] = useState([]);

    const checkShop = useCallback(
        (shop) => (!cart.length || cart[0].shop.id === shop.id),
        [cart]
    );

    const addToCart = useCallback(
        async (shop, product, quantity) => {
            let temp = _.clone(cart);

            if (!checkShop(shop)) {
                if (!await confirm(`Rozpocząć nowe zamówienie? Twoje zamówienie w sklepie ${cart[0]?.shop?.name} zostanie usunięte.`, confirmOptions)) {
                    window.location.href = '/'; //TODO: poprawić tak, by używało routera
                    return;
                } else {
                    temp = [];
                }
            }

            if (_.find(temp, (pos) => pos.product.id === product.id))
                temp.forEach((pos) => {
                    if (pos.product.id === product.id)
                        pos.quantity += quantity;
                });
            else
                temp.push({ shop, product, quantity, selected: true })

            setCart(temp);
        },
        [cart, checkShop]
    );

    const remove = useCallback(
        (position) => setCart(cart.filter((pos) => pos !== position)),
        [cart]
    );

    const removeSelected = useCallback(
        () => setCart(cart.filter((pos) => !pos.selected)),
        [cart]
    );

    const removeAll = useCallback(
        () => setCart([]),
        []
    );

    const setSelected = useCallback(
        (position, isSelected) => {
            let temp = _.clone(cart);

            temp.forEach((pos) => {
                if (pos === position)
                    pos.selected = isSelected;
            });

            setCart(temp);
        },
        [cart]
    );

    const selectAll = useCallback(
        () => {
            let temp = [...cart];
            let value = !_.every(temp, ['selected', true]);

            temp.forEach((pos) => {
                pos.selected = value;
            });

            setCart(temp);
        },
        [cart]
    );

    const decrasePosition = useCallback(
        (position) => {
            let temp = _.clone(cart);

            temp.forEach((pos) => {
                if (pos === position)
                    pos.quantity--;
            });

            setCart(temp);
        },
        [cart]
    );

    const incrasePosition = useCallback(
        (position) => {
            let temp = _.clone(cart);

            temp.forEach((pos) => {
                if (pos === position)
                    pos.quantity++;
            });

            setCart(temp);
        },
        [cart]
    );

    const setPositionQuantity = useCallback(
        (position, quantity) => {
            let temp = _.clone(cart);

            temp.forEach((pos) => {
                if (pos === position)
                    pos.quantity = quantity;
            });

            setCart(temp);
        },
        [cart]
    );

    const getCartQuantity = useCallback(
        () => _.sumBy(cart.filter((pos) => pos.selected), (pos) => pos.quantity),
        [cart]
    );

    const getCartAmount = useCallback(
        () => _.sumBy(cart.filter((pos) => pos.selected), (pos) => pos.product.grosPrice * pos.quantity),
        [cart]
    );

    const getCartDiscount = useCallback(
        () => _.sumBy(cart.filter((pos) => pos.selected && pos.discount), (pos) => pos.discount),
        [cart]
    );

    const setPositionMessage = useCallback(
        (position, message) => {
            let temp = _.clone(cart);

            temp.forEach((pos) => {
                if (pos === position)
                    pos.message = message;
            });

            setCart(temp);
        },
        [cart]
    );

    const setPositionDiscount = useCallback(
        (products, discount, isPercentage) => {
            let temp = _.clone(cart);

            temp.forEach((pos) => {
                if (_.find(products, (p) => p.productId === pos.product.id)) {
                    pos.discount = (isPercentage) ? numberWithTwoPlaces(pos.product.grosPrice * (discount / 100))
                                                : numberWithTwoPlaces(discount);
                }
            })

            setCart(temp);
        },
        [cart]
    )

    const clearPositionDiscount = useCallback(
        (temp) => {
            temp.forEach((pos) => {
                pos.discount = undefined;
            });

            return temp;
        },
        []
    );

    const clearDiscount = useCallback(
        () => setCart(clearPositionDiscount(_.clone(cart))),
        [cart, clearPositionDiscount]
    );

    useEffect(() => {
        setCart(clearPositionDiscount(JSON.parse(localStorage.getItem('cart')) ?? []));
    }, [clearPositionDiscount]);

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);


    const value = useMemo(
        () => ({
            cart,
            setCart,
            addToCart,
            remove,
            removeSelected,
            removeAll,
            setSelected,
            selectAll,
            decrasePosition,
            incrasePosition,
            setPositionQuantity,
            getCartQuantity,
            getCartAmount,
            setPositionMessage,
            setPositionDiscount,
            getCartDiscount,
            clearDiscount
        }),
        [
            cart,
            setCart,
            addToCart,
            remove,
            removeSelected,
            removeAll,
            setSelected,
            selectAll,
            decrasePosition,
            incrasePosition,
            setPositionQuantity,
            getCartQuantity,
            getCartAmount,
            setPositionMessage,
            setPositionDiscount,
            getCartDiscount,
            clearDiscount
        ]
    );

    return (
        <CartContext.Provider value={value}>
          {children}
        </CartContext.Provider>
    );
};

export {
    CartContext,
    CartContextProvider
}