import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import css from './Cart.module.css';
import { CartContext } from '../../hooks/CartContext';
import { getTwoPlacesNumberString } from '../../services/common/Numbers';

function SummarySidebar(props) {
    const {
        onContinue,
        onDiscardCart,
    } = props;

    const { cart, getCartAmount } = useContext(CartContext);

    return (
        <div className={css.SummarySidebarContainer}>
            <div className={css.SummaryPriceContainer}>
                <span>Do zapłaty</span>
                <div className={css.Price}>
                    {getTwoPlacesNumberString(getCartAmount())} zł
                    <small>+ dostawa</small>
                </div>
            </div>
            <button className={css.ContinueCart} onClick={onContinue}>Dostawa i płatność</button>
            {/* <Link to={cart.length > 0 ? `/shop/${cart[0].shop.id}` : '/'} className={css.ContinueShopping}>Kontynuuj zakupy</Link>
            <button className={css.DiscardCart} onClick={onDiscardCart}>Anuluj zamówienie</button> */}
        </div>
    )
}

SummarySidebar.propTypes = {
    onContinue: PropTypes.func.isRequired
};

export default SummarySidebar;