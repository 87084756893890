import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import css from './Cart.module.css';
import _ from 'lodash';
import { Checkbox, FormControlLabel } from '@mui/material';
import { getNumberString, getTwoPlacesNumberString, numberWithTwoPlaces } from '../../services/common/Numbers';
import { CartContext } from '../../hooks/CartContext';

function FinalizeSidebar(props) {
    const {
        discount,
        delivery,
        onNewsletterChange,
        onSubmitForm,
        onBackToCart,
        onDiscardCart,
    } = props;
    const [rulesAcceptance, setRulesAcceptance] = React.useState(false);
    const [newsletter, setNewsletter] = React.useState(false);

    const { getCartAmount } = useContext(CartContext);

    return (
        <div className={css.FinalizeSidebarContainer}>
            <span className={css.Title}>Podsumowanie</span>
            <div className={css.Price}>
                <small>Wartość przedmiotów / usług</small>
                {getTwoPlacesNumberString(getCartAmount())} zł
            </div>
            <div className={css.Price}>
                <small>Dostawa</small>
                {getTwoPlacesNumberString(delivery)} zł
            </div>
            {discount > 0 &&
                <div className={`${css.Price} ${css.Discount}`}>
                    <small>Wartość naliczonych rabatów</small>
                    {getTwoPlacesNumberString(discount * -1)} zł
                </div>
            }
            <div className={`${css.Price} ${css.Summary}`}>
                <small>Razem</small>

                {getTwoPlacesNumberString((getCartAmount() - discount) + delivery)} zł
            </div>
            <hr style={{margin: '30px 0'}} />
            <FormControlLabel
                control={<Checkbox onClick={() => setRulesAcceptance(!rulesAcceptance)} checked={rulesAcceptance} />}
                label={
                    <span>
                        Akceptuję
                        <Link to='/'>politykę prywatności</Link>
                        oraz
                        <Link to='/'>regulamin</Link>
                    </span>
                }
                className="w-100 big-checkbox-label"
                sx={{
                    '&': {
                        font: 'normal normal normal 1.875em Effra',
                        color: 'var(--header-text-color)'
                    },
                    '& a': {
                        margin: '0 5px',
                        color: 'var(--accent-text-color)',
                        textDecoration: 'none'
                    }
                }} />
                <FormControlLabel
                    control={<Checkbox onClick={() => { setNewsletter(!newsletter); onNewsletterChange(!newsletter); }} checked={newsletter} />}
                    label={
                        <span>Chcę zapisać się do newslettera</span>
                    }
                    className="w-100 big-checkbox-label"
                    sx={{
                        '&': {
                            font: 'normal normal normal 1.875em Effra',
                            color: 'var(--header-text-color)'
                        }
                    }} />
            <hr style={{margin: '30px 0'}} />
            <button disabled={!rulesAcceptance} className={css.ContinueCart} onClick={onSubmitForm}>Zamawiam i płacę</button>
            <button className={css.OrderAndPay} onClick={onBackToCart}>Wróć do koszyka</button>
            <button className={css.DiscardCart} onClick={onDiscardCart}>Anuluj zamówienie</button>
        </div>
    );
}

FinalizeSidebar.propTypes = {
    onSubmitForm: PropTypes.func.isRequired,
    onBackToCart: PropTypes.func.isRequired,
    onDiscardCart: PropTypes.func.isRequired,
};

export default FinalizeSidebar;