import React, {  } from 'react';
import { deliveryMethods } from '../../configurations/deliveryMethods';
import css from './Cart.module.css';

function FinalizeDeliverySection({ selected, onChange }) {
    return (
        <div className={css.DeliveryMethodContainer}>
            <span className={css.CardTitle}>Dostawa</span>
            <div className="d-flex flex-row mt-3">
                {deliveryMethods.map((method, index) =>
                    <div
                        key={index}
                        className={`${css.DeliveryMethodBox} ${(selected === method) ? css.Active : ''}`}
                        onClick={() => onChange(method)}
                    >
                        <span className={css.Title}>{method.name}</span>
                        <img src={method.icon} alt={method.name} />
                        <span className={css.Price}>{parseFloat(method.price).toFixed(2).toString().replace('.', ',')} zł</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default FinalizeDeliverySection;