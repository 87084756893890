import React, {  } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import FormField from '../common/FormField';
import css from './Cart.module.css';

function FinalizeChooseShippingNotify({ checked, fieldRegistration, onChange }) {
    return (
        <>
            <div className="w-100 mt-5" style={{marginBottom: '30px'}}>
                <span className={css.CardTitle}>Powiadomienie o przesyłce</span>
            </div>
            <Grid container style={{flexDirection: 'column', gap: '30px'}}>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox checked={checked} onClick={onChange} />}
                        label={
                            <div className="d-flex flex-row w-100 align-items-center">
                                Chcę otrzymywać powiadomienia na e-mail

                            </div>
                        }
                        className="w-100"
                        sx={{
                            '&': {
                                padding: 0,
                                margin: 0,
                                gap: '20px',
                            },
                            '& .MuiCheckbox-root': {
                                padding: 0,
                            },
                            '& .MuiTypography-root': {
                                width: '100%',
                                font: 'normal normal normal 18px/24px Effra',
                                color: '#202020'
                            },
                            '& .MuiTypography-root .form-floating': {
                                marginLeft: '30px',
                                width: '50%'
                            },
                            '& .MuiTypography-root .form-floating label': {
                                top: 0,
                                left: 0
                            }
                        }} />
                </Grid>
                <Grid item className="form-floating">
                    <FormField
                        props={{
                            disabled: !checked,
                            required: checked,
                            type: "text",
                            id: "additionalEmail",
                            placeholder: "Twój adres email",
                        }}
                        capitalized
                        className="form-control"
                        register={fieldRegistration}
                        labelClassName={`${css.MessageInputLabel} ${css.DeliveryNotifyMessageInputLabel}`}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default FinalizeChooseShippingNotify;